import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { cloneDeep } from 'lodash';
import { getFirstTimeRightName } from '@/support/FirstTimeRight';
import FirstTimeRightDialog from '@/views/Reports/first-time-right-dialog/FirstTimeRightDialog.vue';

@Component<ReportFtrInfo>({
  components: {
    FirstTimeRightDialog,
  },
})

export default class ReportFtrInfo extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  @Prop()
  protected report!: Report;

  protected reportFtrInfo: Report | null = null;

  // firstimeright
  protected isEditingFirstTimeRight = false;

  protected isDisabledFirstTimeRight = false;

  protected selectedFirstTimeRight: any | null = null;

  protected firstTimeRights: any | null = null;

  protected get allowEdit() {
    if (this.$store.state.Auth.hasRole('expert') && this.report.archive_info && this.report.archive_info.is_archived_for_expert) {
      return false;
    }

    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  protected async mounted() {
    this.initialize();
  }

  protected initialize() {
    this.isLoading = true;
    this.cloneReport();
    this.parseFirtTimeRights();
    this.isLoading = false;
  }

  protected cloneReport() {
    this.reportFtrInfo = cloneDeep(this.report);
  }

  protected parseFirtTimeRights() {
    if (! this.reportFtrInfo) {
      return;
    }

    this.firstTimeRights = [
      {
        name: 'IMG',
        stats_first_time_right_tcmg: this.reportFtrInfo.stats_first_time_right_tcmg,
        stats_first_time_right_tcmg_reasons: this.reportFtrInfo.stats_first_time_right_tcmg_reasons,
        stats_first_time_right_tcmg_comment: this.reportFtrInfo.stats_first_time_right_tcmg_comment,
      },
      {
        name: 'Bureau',
        stats_first_time_right_bureau: this.reportFtrInfo.stats_first_time_right_bureau,
        stats_first_time_right_bureau_reasons: this.reportFtrInfo.stats_first_time_right_bureau_reasons,
        stats_first_time_right_bureau_comment: this.reportFtrInfo.stats_first_time_right_bureau_comment,
      },
    ];
  }

  protected parseFirstTimeRightName(value: string, tcmg: boolean) {
    return getFirstTimeRightName(value, tcmg);
  }

  protected editFirstTimeRight(firstimeright: any, disabled = false) {
    this.selectedFirstTimeRight = firstimeright;
    this.isEditingFirstTimeRight = true;
    this.isDisabledFirstTimeRight = disabled;
  }

  protected firstTimeRightUpdated() {
    this.$emit('reloadReport');
  }
}
