var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              { staticClass: "profileContainer", attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("First time right")
                  ])
                ])
              ],
              1
            ),
            _c(
              "v-layout",
              { staticClass: "item__list", attrs: { wrap: "" } },
              [
                _c(
                  "v-flex",
                  { staticClass: "label", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs8: "" } }, [
                          _vm._v("Resultaat")
                        ]),
                        _c("v-flex", { attrs: { xs2: "" } }, [
                          _vm._v("Partij")
                        ]),
                        _c("v-flex", { attrs: { xs2: "" } })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { staticClass: "list__item", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-center": "" } },
                      [
                        _c("v-flex", { attrs: { xs8: "" } }, [
                          _vm.reportFtrInfo.stats_first_time_right_tcmg
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "statusPill statusBackgroundColor--green"
                                },
                                [_vm._v(" First Time Right ")]
                              )
                            : _vm._e(),
                          !_vm.reportFtrInfo.stats_first_time_right_tcmg
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "statusPill statusBackgroundColor--red"
                                },
                                [_vm._v(" NOT First Time Right ")]
                              )
                            : _vm._e()
                        ]),
                        _c("v-flex", { attrs: { xs2: "" } }, [_vm._v("IMG")]),
                        _vm.allowEdit && !_vm.syncing && !_vm.report.is_offline
                          ? _c(
                              "v-flex",
                              {
                                staticClass: "item__icon",
                                attrs: { xs2: "", "text-xs-right": "" }
                              },
                              [
                                _vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.editFirstTimeRight(
                                              _vm.firstTimeRights[0],
                                              _vm.$store.state
                                                .isServiceOrganization
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" remove_red_eye ")]
                                    )
                                  : _vm._e(),
                                !_vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.editFirstTimeRight(
                                              _vm.firstTimeRights[0]
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" edit ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { staticClass: "list__item", attrs: { xs12: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "align-center": "" } },
                      [
                        _c("v-flex", { attrs: { xs8: "" } }, [
                          _vm.reportFtrInfo.stats_first_time_right_bureau
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "statusPill statusBackgroundColor--green"
                                },
                                [_vm._v(" First Time Right ")]
                              )
                            : _vm._e(),
                          !_vm.reportFtrInfo.stats_first_time_right_bureau
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "statusPill statusBackgroundColor--red"
                                },
                                [_vm._v(" NOT First Time Right ")]
                              )
                            : _vm._e()
                        ]),
                        _c("v-flex", { attrs: { xs2: "" } }, [
                          _vm._v("Bureau")
                        ]),
                        _vm.allowEdit && !_vm.syncing && !_vm.report.is_offline
                          ? _c(
                              "v-flex",
                              {
                                staticClass: "item__icon",
                                attrs: { xs2: "", "text-xs-right": "" }
                              },
                              [
                                !_vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.editFirstTimeRight(
                                              _vm.firstTimeRights[1],
                                              !_vm.$store.state
                                                .isServiceOrganization
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" remove_red_eye ")]
                                    )
                                  : _vm._e(),
                                _vm.$store.state.isServiceOrganization
                                  ? _c(
                                      "v-icon",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.editFirstTimeRight(
                                              _vm.firstTimeRights[1]
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" edit ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.isEditingFirstTimeRight
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isEditingFirstTimeRight,
                callback: function($$v) {
                  _vm.isEditingFirstTimeRight = $$v
                },
                expression: "isEditingFirstTimeRight"
              }
            },
            [
              _c("FirstTimeRightDialog", {
                attrs: {
                  report: _vm.reportFtrInfo,
                  selected: _vm.selectedFirstTimeRight,
                  isDisabled: _vm.isDisabledFirstTimeRight
                },
                on: { firstTimeRightUpdated: _vm.firstTimeRightUpdated },
                model: {
                  value: _vm.isEditingFirstTimeRight,
                  callback: function($$v) {
                    _vm.isEditingFirstTimeRight = $$v
                  },
                  expression: "isEditingFirstTimeRight"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }