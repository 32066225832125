import { Report } from '@/models/Report';
import { DateFormatter } from '@/support/DateFormatter';
import { cloneDeep } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { formatDate } from '@/support/String';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DateTime } from 'luxon';
import { userRoles } from '@/models/User';

@Component<ReportDates>({})
export default class ReportDates extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  protected plannedAt = new DateFormatter();

  protected submittedAt = new DateFormatter();

  protected userRoles = userRoles;

  @Prop()
  protected report!: Report;

  protected reportDates: Report | null = null;

  protected databaseDate = '0000-00-00 00:00:00';

  protected mask: {[key: string]: string} = {
    time: '##:##',
  };

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }

    if (this.$store.state.Auth.hasRole(this.userRoles.PreControllerRoles)) {
      return true;
    }

    if (this.report.hasStatus([
      'opname_in_progress',
      'in_progress',
      'rejected',
      'zienswijze_in_progress',
      'zienswijze_rejected',
      'objection_in_progress',
      'objection_rejected',
      'smr_in_progress',
      'smr_rejected',
    ])) {
      return true;
    }

    return false;
  }

  protected async mounted() {
    this.initialize();
  }

  protected initialize() {
    this.isLoading = true;
    this.cloneReport();
    this.isLoading = false;
  }

  protected cloneReport() {
    this.reportDates = cloneDeep(this.report);

    this.plannedAt.selectedDate = this.reportDates.planned_at && this.reportDates.planned_at !== this.databaseDate
      ? this.reportDates.planned_at
      : '';

    this.plannedAt.selectedTime = this.reportDates.planned_at && this.reportDates.planned_at !== this.databaseDate
      ? formatDate(this.reportDates.planned_at, 'HH:mm')
      : '';

    this.submittedAt.selectedDate = this.reportDates.submitted_at && this.reportDates.submitted_at !== this.databaseDate
      ? this.reportDates.submitted_at
      : '';

    this.submittedAt.selectedTime = this.reportDates.submitted_at && this.reportDates.submitted_at !== this.databaseDate
      ? formatDate(this.reportDates.submitted_at, 'HH:mm')
      : '';
  }

  protected cancelReportDates() {
    this.isEditing = false;
    this.initialize();
  }

  protected updateReport() {
    if (! this.reportDates) {
      return;
    }

    this.isUpdating = true;

    const payload: {[key: string]: any} = {};

    if (! this.$store.state.isServiceOrganization) {
      payload.planned_at = this.plannedAt.selectedDate ? DateTime.fromSQL(`${this.plannedAt.selectedDate} ${this.plannedAt.selectedTime}`).toFormat('yyyy-LL-dd HH:mm:ss') : '';
      payload.submitted_at = this.submittedAt.selectedDate ? DateTime.fromSQL(`${this.submittedAt.selectedDate} ${this.submittedAt.selectedTime}`).toFormat('yyyy-LL-dd HH:mm:ss') : '';
    }

    this.reportDates
      .update(payload)
      .then(() => {
        this.isUpdating = false;
        this.isEditing = false;
        this.$emit('reloadReport');
      })
      .catch((error: AxiosError) => {
        this.isUpdating = false;
        ErrorHandler.network(error);
      });
  }
}
