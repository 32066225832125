var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              { staticClass: "profileContainer", attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("Aantal toegekende schades")
                  ])
                ])
              ],
              1
            ),
            _c(
              "v-layout",
              {
                staticClass: "profileContainer",
                attrs: { row: "", wrap: "", "pt-0": "" }
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _vm.statistics
                      ? _c(
                          "v-layout",
                          {
                            staticClass: "item__list",
                            attrs: { wrap: "", row: "", "my-3": "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Causale schades")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statistics.damage_causaal_count
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Niet causale schades")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statistics
                                              .damage_not_causaal_count
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("EBS Identiek NAM")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statistics.ebs_identiek_nam
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("EBS Identiek IMG")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statistics.ebs_identiek_tcmg
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Onbekend")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.statistics.damage_onbekend)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Totaal")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.statistics.damage_count)
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }