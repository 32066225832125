var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          !_vm.isLoading
            ? _c("template", { slot: "header" }, [
                _c("h2", { staticClass: "dialog-title" }, [
                  _vm._v("(Ambtelijk) Bezwaar Advies rapport maken")
                ])
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "250px"
                    }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-space-between": "",
                        "align-baseline": "",
                        "mb-4": ""
                      }
                    },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("span", { staticClass: "fadedText" }, [
                          _vm._v(
                            "Vul het bezwaar nummer in en kies het rapport type."
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "", "hide-details": "" },
                              model: {
                                value: _vm.type,
                                callback: function($$v) {
                                  _vm.type = $$v
                                },
                                expression: "type"
                              }
                            },
                            [
                              _vm.isAdvanced
                                ? [
                                    _c("table", [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-radio",
                                              {
                                                attrs: {
                                                  value:
                                                    _vm.ReportTypes
                                                      .BEZWAAR_AMBTELIJK_2,
                                                  color: "secondary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "label" },
                                                    slot: "label"
                                                  },
                                                  [_vm._v("Ambtelijk bezwaar")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-radio",
                                              {
                                                attrs: {
                                                  value:
                                                    _vm.ReportTypes
                                                      .BEZWAAR_BAC_2,
                                                  color: "secondary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "label" },
                                                    slot: "label"
                                                  },
                                                  [_vm._v("BAC")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("tr", [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-radio",
                                              {
                                                attrs: {
                                                  value:
                                                    _vm.ReportTypes
                                                      .BEZWAAR_AMBTELIJK_2_CALCULATIE,
                                                  color: "secondary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "label" },
                                                    slot: "label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Ambtelijk bezwaar - Calculatie"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-radio",
                                              {
                                                attrs: {
                                                  value:
                                                    _vm.ReportTypes
                                                      .BEZWAAR_BAC_2_CALCULATIE,
                                                  color: "secondary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "label" },
                                                    slot: "label"
                                                  },
                                                  [_vm._v("BAC - Calculatie")]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ])
                                  ]
                                : [
                                    _c(
                                      "v-radio",
                                      {
                                        attrs: {
                                          value:
                                            _vm.ReportTypes
                                              .ADVIESRAPPORT_BEZWAAR_AMBTELIJK,
                                          color: "secondary"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label"
                                          },
                                          [_vm._v("Ambtelijk bezwaar")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-radio",
                                      {
                                        attrs: {
                                          value: _vm.ReportTypes.BEZWAAR_2021,
                                          color: "secondary"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "label" },
                                            slot: "label"
                                          },
                                          [_vm._v("BAC")]
                                        )
                                      ]
                                    )
                                  ]
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            staticClass: "pa-0 ma-0",
                            attrs: {
                              label: "Nummer",
                              rules: [_vm.rules.required, _vm.rules.minLength6]
                            },
                            model: {
                              value: _vm.number,
                              callback: function($$v) {
                                _vm.number = $$v
                              },
                              expression: "number"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs6: "" } }, [
                        _vm._v("| " + _vm._s(_vm.report.case_number))
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { sm12: "" } },
                        [
                          _c("v-autocomplete", {
                            staticClass: "with-border",
                            attrs: {
                              items: _vm.users,
                              "search-input": _vm.userSearch,
                              label: "Dossierbeheerder",
                              "item-text": "name",
                              "item-value": "uuid",
                              "hide-details": "",
                              "no-filter": ""
                            },
                            on: {
                              "update:searchInput": function($event) {
                                _vm.userSearch = $event
                              },
                              "update:search-input": function($event) {
                                _vm.userSearch = $event
                              },
                              keyup: _vm.debouncedSearchUsers
                            },
                            model: {
                              value: _vm.selectedUser,
                              callback: function($$v) {
                                _vm.selectedUser = $$v
                              },
                              expression: "selectedUser"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { sm12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.opnameVariantItems,
                              "item-text": "name",
                              "item-value": "value",
                              label: "Opname variant",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.opnameVariant,
                              callback: function($$v) {
                                _vm.opnameVariant = $$v
                              },
                              expression: "opnameVariant"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "mr-3 fadedText link",
                              on: { click: _vm.close }
                            },
                            [_vm._v("Annuleren")]
                          ),
                          _c(
                            "MiButton",
                            {
                              staticClass: "ml-1",
                              attrs: {
                                disabled: _vm.isDisabled,
                                color: "success",
                                small: "true"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.create($event)
                                }
                              }
                            },
                            [_vm._v(" Aanmaken ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }