import { AxiosError } from 'axios';
import { User } from '@/models/User';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Rpc } from '@/models/Rpc';
import { Report } from '@/models/Report';
import { ReportTypes } from '@/support/ReportTypes';
import { debounce } from 'lodash';
import { rules } from '@/support/ValidationRules';
import { ObjectionOpnameVariants, OpnameVariantLabels } from '@/items/OpnameVariant';

@Component<BezwaarDialog>({})
export default class BezwaarDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected ReportTypes = ReportTypes;

  protected isLoading = false;

  protected number = '';

  protected type = '';

  protected opnameVariant = '';

  protected debouncedSearchUsers: Function = debounce(this.fetchUsers, 300);

  protected userSearch = '';

  protected users: User[] = [];

  protected selectedUser = '';

  protected rules = rules;

  protected opnameVariantItems: {name: string, value: string}[] = ObjectionOpnameVariants.map((variant: string) => ({ name: OpnameVariantLabels[`${variant}`], value: variant }));

  protected mounted(): void {
    this.fetchUsers();
  }

  protected close() {
    this.$emit('input', false);
  }

  protected async create(): Promise<void> {
    if (! this.report) {
      return;
    }

    this.isLoading = true;

    const payload: {[key: string]: any} = {
      signature: 'bezwaar:create',
      body: {
        report: this.report.uuid,
        objection_number: this.number,
        report_type: this.type,
        report_handler: this.selectedUser,
        opname_variant: this.opnameVariant,
      },
    };

    await new Rpc()
      .rpcPost(payload)
      .then((response: Record<string, any>) => {
        this.isLoading = false;
        this.close();
        this.$router.push({ name: 'report.detail', params: { id: response.id } });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected async fetchUsers() {
    this.users = await new User()
      .filter('search', this.userSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get isDisabled() {
    return ! this.type || ! this.number || (this.number.length < 6) || ! this.selectedUser || ! this.opnameVariant;
  }

  protected get isAdvanced(): boolean {
    const typeId = this.report.type?.uuid;
    if (! typeId) {
      return false;
    }

    return [
      ReportTypes.REGULIER_2021,
      ReportTypes.BEZWAAR_AMBTELIJK_2,
      ReportTypes.BEZWAAR_AMBTELIJK_2_CALCULATIE,
      ReportTypes.BEZWAAR_BAC_2,
      ReportTypes.BEZWAAR_BAC_2_CALCULATIE,
    ].includes(typeId as ReportTypes);
  }
}
