var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h2", { staticClass: "dialog-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.report.isBezwaarReport
                            ? "Bezwaar Nader Advies"
                            : "Nader Advies"
                        )
                      )
                    ]),
                    _c("span", { staticClass: "fadedText" }, [
                      _vm._v(
                        "Weet je zeker dat je een verzoek tot nader advies wilt aanmaken?"
                      )
                    ])
                  ]),
                  !_vm.report || _vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.report && !_vm.isLoading
                    ? [
                        !_vm.report.isBezwaarReport
                          ? [
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Het Nader Advies is een PRIO",
                                      "hide-details": "",
                                      name: "is_prio",
                                      color: "#a09b1b"
                                    },
                                    model: {
                                      value: _vm.is_prio,
                                      callback: function($$v) {
                                        _vm.is_prio = $$v
                                      },
                                      expression: "is_prio"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { shrink: "" } },
                                [
                                  _c("v-icon", { attrs: { color: "alert" } }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-5": "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: { click: _vm.close }
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.create($event)
                                          }
                                        }
                                      },
                                      [_vm._v("aanmaken")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }