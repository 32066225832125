var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              { staticClass: "profileContainer", attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("Schadebedrag")
                  ])
                ])
              ],
              1
            ),
            _c(
              "v-layout",
              {
                staticClass: "profileContainer",
                attrs: { row: "", wrap: "", "pt-0": "" }
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _vm.total_repairs
                      ? _c(
                          "v-layout",
                          {
                            staticClass: "item__list",
                            attrs: { wrap: "", "my-3": "" }
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Schades aan gebouw/werk")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.total_repairs.gebouw_in
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Gevolgschades schades")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.total_repairs.gevolg_in
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "fadedText",
                                        attrs: { xs8: "" }
                                      },
                                      [_vm._v("Klein werk toeslag")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.total_repairs
                                                .klein_werk_toeslag
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.total_repairs.index_percentage > 0
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass: "list__item",
                                    attrs: { xs12: "" }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "fadedText",
                                            attrs: { xs8: "" }
                                          },
                                          [
                                            _vm._v(
                                              " Totaalprijs aangepast naar prijspeil 2020 (" +
                                                _vm._s(
                                                  _vm.report.index_percentage
                                                ) +
                                                "%): "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass: "text-xs-right",
                                            attrs: { xs4: "" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.total_repairs.index_amount
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-flex",
                              {
                                staticClass: "list__item",
                                attrs: { xs12: "" }
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "strongText",
                                        attrs: { xs8: "" }
                                      },
                                      [
                                        _vm._v(
                                          "Totaal uit te betalen schadebedrag incl. BTW"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { xs4: "" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.total_repairs.total_payment
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }