var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional profile" },
    [
      _c(
        "v-layout",
        { staticClass: "profileContainer", attrs: { row: "", wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _vm.report.isClosed && _vm.$store.state.isServiceOrganization
              ? _c("span", { staticClass: "profile__userName" }, [
                  _vm._v(" Vervolgacties ")
                ])
              : _c("span", { staticClass: "profile__userName" }, [
                  _vm._v("Editors")
                ])
          ])
        ],
        1
      ),
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      _vm.report && !_vm.isArchived
        ? [
            !_vm.isLoading && _vm.$store.state.isServiceOrganization
              ? _c("Sidemenu", { attrs: { items: _vm.editorClosedItems } })
              : _vm._e(),
            !_vm.isLoading &&
            !_vm.report.isClosed &&
            !_vm.report.isProblem &&
            !_vm.report.isPlanningOnHold
              ? _c("Sidemenu", { attrs: { items: _vm.editorItems } })
              : _vm._e()
          ]
        : [_c("p", [_vm._v("Dit dossier is gearchiveerd")])],
      _vm.isCreatingZienswijze
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isCreatingZienswijze,
                callback: function($$v) {
                  _vm.isCreatingZienswijze = $$v
                },
                expression: "isCreatingZienswijze"
              }
            },
            [
              _c("ZienswijzeDialog", {
                attrs: { report: _vm.report },
                model: {
                  value: _vm.isCreatingZienswijze,
                  callback: function($$v) {
                    _vm.isCreatingZienswijze = $$v
                  },
                  expression: "isCreatingZienswijze"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isCreatingBezwaar
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isCreatingBezwaar,
                callback: function($$v) {
                  _vm.isCreatingBezwaar = $$v
                },
                expression: "isCreatingBezwaar"
              }
            },
            [
              _c("BezwaarDialog", {
                attrs: { report: _vm.report },
                model: {
                  value: _vm.isCreatingBezwaar,
                  callback: function($$v) {
                    _vm.isCreatingBezwaar = $$v
                  },
                  expression: "isCreatingBezwaar"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isChangingStatus
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isChangingStatus,
                callback: function($$v) {
                  _vm.isChangingStatus = $$v
                },
                expression: "isChangingStatus"
              }
            },
            [
              _c("ChangeStatusDialog", {
                attrs: {
                  report: _vm.report,
                  statusses: _vm.statusChangeDialogOptions.statusses,
                  title: _vm.statusChangeDialogOptions.title,
                  subtitle: _vm.statusChangeDialogOptions.subtitle
                },
                on: { reloadReport: _vm.reloadReport },
                model: {
                  value: _vm.isChangingStatus,
                  callback: function($$v) {
                    _vm.isChangingStatus = $$v
                  },
                  expression: "isChangingStatus"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isSendingToStatus
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isSendingToStatus,
                callback: function($$v) {
                  _vm.isSendingToStatus = $$v
                },
                expression: "isSendingToStatus"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.close } },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(" " + _vm._s(_vm.sendStatusDialog.title) + " ")
                  ]),
                  [
                    _c(
                      "template",
                      { slot: "content" },
                      [
                        _vm.isLoading
                          ? _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          : _vm._e(),
                        !_vm.isLoading
                          ? [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("span", { staticClass: "fadedText" }, [
                                  _vm._v(
                                    _vm._s(_vm.sendStatusDialog.description)
                                  )
                                ])
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "template",
                      { slot: "actions" },
                      [
                        !_vm.isLoading
                          ? _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        staticClass: "mr-1",
                                        class:
                                          _vm.sendStatusDialog.confirmClass,
                                        attrs: {
                                          outline: "true",
                                          color: "warning",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.sendStatusDialog.cancelAction()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sendStatusDialog.cancel
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        staticClass: "ml-1",
                                        class:
                                          _vm.sendStatusDialog.confirmClass,
                                        attrs: {
                                          color: "success",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.sendStatusDialog.confirmAction()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sendStatusDialog.confirm
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isSendingToJz
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isSendingToJz,
                callback: function($$v) {
                  _vm.isSendingToJz = $$v
                },
                expression: "isSendingToJz"
              }
            },
            [
              _c(
                "DefaultDialog",
                { on: { close: _vm.close } },
                [
                  _c("template", { slot: "header" }, [
                    _vm._v(" " + _vm._s(_vm.sendStatusDialog.title) + " ")
                  ]),
                  [
                    _c(
                      "template",
                      { slot: "content" },
                      [
                        _vm.isLoading
                          ? _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          : _vm._e(),
                        !_vm.isLoading
                          ? [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("span", { staticClass: "fadedText" }, [
                                  _vm._v(
                                    _vm._s(_vm.sendStatusDialog.description)
                                  )
                                ])
                              ]),
                              _vm.report &&
                              !_vm.report.hasStatus("specials_in_review")
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mt-3": "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.users,
                                          "item-value": "id",
                                          "item-text": "name",
                                          "hide-details": "",
                                          label: "Medewerker",
                                          placeholder: "Zoek een Medewerker",
                                          "search-input": _vm.userSearch
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.userSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.userSearch = $event
                                          },
                                          keyup: _vm.debouncedSearchUsers
                                        },
                                        model: {
                                          value: _vm.selectedUser,
                                          callback: function($$v) {
                                            _vm.selectedUser = $$v
                                          },
                                          expression: "selectedUser"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "template",
                      { slot: "actions" },
                      [
                        !_vm.isLoading
                          ? _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: {
                                          click: function($event) {
                                            return _vm.sendStatusDialog.cancelAction()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sendStatusDialog.cancel
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: !_vm.report.hasStatus(
                                            "specials_in_review"
                                          )
                                            ? !_vm.selectedUser
                                            : false,
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.sendStatusDialog.confirmAction()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.sendStatusDialog.confirm
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }