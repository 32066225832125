var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading && _vm.reportInfo
        ? [
            [
              _c(
                "v-layout",
                { staticClass: "profileContainer", attrs: { wrap: "" } },
                [
                  _vm.allowEdit &&
                  !_vm.isEditing &&
                  !_vm.syncing &&
                  !_vm.report.is_offline
                    ? _c(
                        "div",
                        { staticClass: "iconContainer" },
                        [
                          _vm.$store.state.isServiceOrganization
                            ? [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        _vm.isCreatingReport = true
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("file_copy")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1428779208
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Dossier aanmaken op basis van: " +
                                          _vm._s(_vm.reportInfo.case_number)
                                      )
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e(),
                          _vm.allowEdit
                            ? [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    staticClass: "edit-icon",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.isEditing = true
                                                        _vm.cloneReport()
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" edit ")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3031686223
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Dossier gegevens aanpassen")
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.isEditing
                    ? _c(
                        "div",
                        { staticClass: "iconContainer" },
                        [
                          !_vm.isUpdating
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "cancel-icon",
                                  on: {
                                    click: function($event) {
                                      _vm.isEditing = false
                                      _vm.cloneReport()
                                      _vm.resetApplicant()
                                    }
                                  }
                                },
                                [_vm._v(" cancel ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.syncing
                    ? _c(
                        "div",
                        { staticClass: "iconContainer" },
                        [
                          _c("LoaderCard", {
                            staticClass: "spinner--inline",
                            attrs: { type: "spinner--small" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "profile__userName" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _vm.reportInfo && _vm.reportInfo.is_prio
                                        ? _c(
                                            "v-icon",
                                            _vm._g(
                                              { staticClass: "prio-icon" },
                                              on
                                            ),
                                            [_vm._v("warning")]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1180155354
                            )
                          },
                          [_c("span", [_vm._v("Dit is een prio dossier")])]
                        ),
                        _c(
                          "h1",
                          { class: _vm.reportInfo.is_prio ? "pl-4" : "" },
                          [_vm._v(_vm._s(_vm.reportInfo.case_number))]
                        )
                      ],
                      1
                    ),
                    _vm.report && _vm.reportInfo.status
                      ? _c(
                          "span",
                          {
                            class:
                              "statusPill statusBackgroundColor--" +
                              _vm.getStatusColor(_vm.reportInfo.status)
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.reportInfo.status.length
                                    ? _vm.getStatusLabel(_vm.reportInfo.status)
                                    : ""
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm.reportInfo &&
                  _vm.reportInfo.planning_status &&
                  _vm.reportInfo.planning_status !== "planned" &&
                  _vm.reportInfo.planning_status !== "no_action_required"
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "statusPill",
                            class: [
                              "statusBackgroundColor--" +
                                _vm.getStatusColor(
                                  _vm.reportInfo.planning_status
                                ),
                              _vm.hasHeropnameStatus ? "with-icon" : ""
                            ]
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.reportInfo.planning_status &&
                                    _vm.reportInfo.planning_status.length
                                    ? _vm.getStatusLabel(
                                        _vm.reportInfo.planning_status
                                      )
                                    : ""
                                ) +
                                " "
                            ),
                            _vm.hasHeropnameStatus
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "remove-icon",
                                    on: {
                                      click: function($event) {
                                        return _vm.updatePlanningStatus()
                                      }
                                    }
                                  },
                                  [_vm._v("close")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            !_vm.isEditing
              ? [
                  _c(
                    "v-layout",
                    {
                      staticClass: "profileContainer",
                      attrs: { row: "", wrap: "", "align-center": "" }
                    },
                    [
                      _vm.reportInfo && _vm.reportInfo.is_test
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("div", { staticClass: "value disabled" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "font-weight-bold primary--text"
                                },
                                [_vm._v("Test dossier")]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.workflowItems,
                              "item-text": "name",
                              "item-value": "value",
                              label: "Workflow",
                              "hide-details": "",
                              disabled: true
                            },
                            model: {
                              value: _vm.reportInfo.workflow,
                              callback: function($$v) {
                                _vm.$set(_vm.reportInfo, "workflow", $$v)
                              },
                              expression: "reportInfo.workflow"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.opnameVariantItems,
                              "item-text": "name",
                              "item-value": "value",
                              label: "Opname variant",
                              "hide-details": "",
                              disabled: true
                            },
                            model: {
                              value: _vm.reportInfo.opname_variant,
                              callback: function($$v) {
                                _vm.$set(_vm.reportInfo, "opname_variant", $$v)
                              },
                              expression: "reportInfo.opname_variant"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "relative", attrs: { xs12: "" } },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Rapport type")
                          ]),
                          _vm.reportInfo.type
                            ? _c("div", { staticClass: "value disabled" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.reportInfo.type.name) + " "
                                )
                              ])
                            : _vm._e(),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _vm.reportInfo &&
                                        _vm.reportInfo.is_special
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                { staticClass: "special-icon" },
                                                on
                                              ),
                                              [_vm._v("star")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2157673380
                              )
                            },
                            [_c("span", [_vm._v("Dit is een special dossier")])]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.managementInfoClassItems,
                              "item-text": "name",
                              "item-value": "value",
                              label: "Rapportage classificatie",
                              "hide-details": "",
                              disabled: true
                            },
                            model: {
                              value: _vm.reportInfo.management_info_class,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.reportInfo,
                                  "management_info_class",
                                  $$v
                                )
                              },
                              expression: "reportInfo.management_info_class"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.WorkFlows,
                              "item-text": "name",
                              "item-value": "value",
                              label: "Planning workflow",
                              disabled: true,
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.reportInfo.planning_workflow,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.reportInfo,
                                  "planning_workflow",
                                  $$v
                                )
                              },
                              expression: "reportInfo.planning_workflow"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Calculatiemodel")
                        ]),
                        _vm.reportInfo.calculation_model
                          ? _c("div", { staticClass: "value disabled" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.reportInfo.calculation_model.name
                                  ) +
                                  " (" +
                                  _vm._s(
                                    _vm.reportInfo.calculation_model.from
                                  ) +
                                  ") " +
                                  _vm._s(_vm.getVatText()) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        !_vm.reportInfo.calculation_model
                          ? _c("div", { staticClass: "value disabled" }, [
                              _vm._v("-")
                            ])
                          : _vm._e()
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Opnamedatum")
                        ]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  _vm.reportInfo.planned_at === _vm.databaseDate
                                    ? "-"
                                    : _vm.reportInfo.planned_at
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Naam aanvrager")
                          ]),
                          _vm.reportInfo.applicant
                            ? [
                                _c("div", { staticClass: "value disabled" }, [
                                  _vm._v(_vm._s(_vm.reportInfo.applicant.name))
                                ]),
                                _c("div", { staticClass: "value disabled" }, [
                                  _vm.reportInfo.applicant.phone
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "tel:" +
                                              _vm.reportInfo.applicant.phone
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reportInfo.applicant.phone
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            : _c("div", { staticClass: "value disabled" }, [
                                _vm._v(" - ")
                              ])
                        ],
                        2
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Naam intermediair")
                          ]),
                          _vm.reportInfo.intermediary
                            ? [
                                _c("div", { staticClass: "value disabled" }, [
                                  _vm._v(
                                    _vm._s(_vm.reportInfo.intermediary.name)
                                  )
                                ]),
                                _c("div", { staticClass: "value disabled" }, [
                                  _vm.reportInfo.intermediary.phone
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "tel:" +
                                              _vm.reportInfo.intermediary.phone
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reportInfo.intermediary
                                                  .phone
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            : _c("div", { staticClass: "value disabled" }, [
                                _vm._v(" - ")
                              ])
                        ],
                        2
                      ),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("div", { staticClass: "label" }, [_vm._v("Adres")]),
                        _vm.reportInfo.address
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.reportInfo.googleMapUrl(),
                                  target: "_blank"
                                }
                              },
                              [
                                _c("div", { staticClass: "value disabled" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.reportInfo.address.street) +
                                      " " +
                                      _vm._s(_vm.reportInfo.address.number) +
                                      " " +
                                      _vm._s(
                                        _vm.reportInfo.address.number_add
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.reportInfo.address.postcode) +
                                      " " +
                                      _vm._s(_vm.reportInfo.address.city) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm.report.isVesNulmetingReport
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Kadastrale aanduiding")
                            ]),
                            _c("div", { staticClass: "value disabled" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.report.printAnswer(
                                      "ToelichtingKadastraalObject"
                                    )
                                      ? _vm.report.printAnswer(
                                          "ToelichtingKadastraalObject"
                                        )
                                      : "-"
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.$store.state.isBETA
                        ? _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Project")
                            ]),
                            _c(
                              "div",
                              { staticClass: "value disabled" },
                              [
                                _vm.reportInfo.job_project &&
                                _vm.reportInfo.job_project.id
                                  ? [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/projecten/" +
                                              _vm.reportInfo.job_project.id
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.reportInfo.job_project.name
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  : [_vm._v("-")]
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.syncing
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "align-center": "",
                            "pt-2": ""
                          }
                        },
                        [
                          _c("v-flex", { attrs: { shrink: "", "pa-2": "" } }, [
                            _c(
                              "a",
                              {
                                staticClass: "button--link",
                                class: {
                                  buttonDisabled:
                                    !_vm.reportInfo.is_final &&
                                    _vm.$store.state.isServiceOrganization
                                },
                                attrs: {
                                  href: _vm.reportInfo.links.preview,
                                  target: "_blank"
                                }
                              },
                              [
                                _c("v-icon", [_vm._v("picture_as_pdf")]),
                                _vm._v(" Rapport ")
                              ],
                              1
                            )
                          ]),
                          !_vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-flex",
                                { attrs: { shrink: "", "pa-2": "" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button--link",
                                      class: {
                                        buttonDisabled: _vm.reportInfo.is_final
                                      },
                                      attrs: {
                                        href: _vm.reportInfo.links.quick,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("picture_as_pdf")]),
                                      _vm._v(" Quick PDF ")
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$store.state.isServiceOrganization &&
                          !_vm.report.isVesNulmetingReport
                            ? _c(
                                "v-flex",
                                { attrs: { shrink: "", "pa-2": "" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button--link",
                                      class: {
                                        buttonDisabled: !_vm.reportInfo.is_final
                                      },
                                      attrs: {
                                        href: _vm.reportInfo.links.summary,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("picture_as_pdf")]),
                                      _vm._v(" Uittreksel ")
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.$store.state.isBETA
                            ? _c(
                                "v-flex",
                                { attrs: { shrink: "", "pa-2": "" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button--link",
                                      attrs: {
                                        href: _vm.reportInfo.links.photos,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("picture_as_pdf")]),
                                      _vm._v(" Foto export ")
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : _vm._e(),
            _vm.isEditing
              ? [
                  !_vm.isUpdating
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "profileContainer",
                          attrs: { row: "", wrap: "", "align-center": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.workflowItems,
                                  "item-text": "name",
                                  "item-value": "value",
                                  label: "Workflow",
                                  disabled:
                                    _vm.report.isBezwaarAdviesCommissieReport ||
                                    _vm.report.isBezwaarAmbtelijkReport,
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.reportInfo.workflow,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reportInfo, "workflow", $$v)
                                  },
                                  expression: "reportInfo.workflow"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.opnameVariantItems,
                                  "item-text": "name",
                                  "item-value": "value",
                                  label: "Opname variant",
                                  "hide-details": ""
                                },
                                model: {
                                  value: _vm.reportInfo.opname_variant,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.reportInfo,
                                      "opname_variant",
                                      $$v
                                    )
                                  },
                                  expression: "reportInfo.opname_variant"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "relative",
                              attrs: { xs12: "", "pr-3": "" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.reportTypes,
                                  "hide-details": "",
                                  label: "Report type",
                                  "item-text": "name",
                                  "item-value": "uuid",
                                  disabled: !_vm.allowReportTypeEdit
                                },
                                model: {
                                  value: _vm.reportInfo.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.reportInfo, "type", $$v)
                                  },
                                  expression: "reportInfo.type"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.vatNumbers,
                                  "item-text": "label",
                                  "item-value": "value",
                                  label: "Calculatiemodel",
                                  "hide-details": "",
                                  disabled:
                                    !_vm.$store.state.Auth.hasRole(
                                      _vm.userRoles.ManagerRoles
                                    ) || _vm.$store.state.isServiceOrganization
                                },
                                model: {
                                  value:
                                    _vm.reportInfo.calculation_model_vat_low,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.reportInfo,
                                      "calculation_model_vat_low",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "reportInfo.calculation_model_vat_low"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.$store.state.isServiceOrganization
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.WorkFlows,
                                        "item-text": "name",
                                        "item-value": "value",
                                        label: "Planning workflow"
                                      },
                                      model: {
                                        value: _vm.reportInfo.planning_workflow,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reportInfo,
                                            "planning_workflow",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "reportInfo.planning_workflow"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Prio",
                                        "hide-details": "",
                                        name: "is_prio",
                                        color: "#a09b1b"
                                      },
                                      model: {
                                        value: _vm.reportInfo.is_prio,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reportInfo,
                                            "is_prio",
                                            $$v
                                          )
                                        },
                                        expression: "reportInfo.is_prio"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Special",
                                        "hide-details": "",
                                        name: "is_special",
                                        color: "#a09b1b"
                                      },
                                      model: {
                                        value: _vm.reportInfo.is_special,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reportInfo,
                                            "is_special",
                                            $$v
                                          )
                                        },
                                        expression: "reportInfo.is_special"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Zetting / Zakking",
                                        "hide-details": "",
                                        name: "is_zetting_zakking",
                                        color: "#a09b1b"
                                      },
                                      model: {
                                        value:
                                          _vm.reportInfo.is_zetting_zakking,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reportInfo,
                                            "is_zetting_zakking",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "reportInfo.is_zetting_zakking"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        label: "Test dossier",
                                        "hide-details": "",
                                        name: "is_test",
                                        color: "#a09b1b"
                                      },
                                      model: {
                                        value: _vm.reportInfo.is_test,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reportInfo,
                                            "is_test",
                                            $$v
                                          )
                                        },
                                        expression: "reportInfo.is_test"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                false
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            items: _vm.applicantSearchResults,
                                            "item-text":
                                              _vm.AanvragerModelKeyEnum.NAME,
                                            "item-value":
                                              _vm.AanvragerModelKeyEnum.ID,
                                            label: "Aanvrager",
                                            clearable: "",
                                            "return-object": "",
                                            "no-data-text":
                                              "Geen aanvragers gevonden",
                                            "search-input":
                                              _vm.applicantSearchQuery
                                          },
                                          on: {
                                            "update:searchInput": function(
                                              $event
                                            ) {
                                              _vm.applicantSearchQuery = $event
                                            },
                                            "update:search-input": function(
                                              $event
                                            ) {
                                              _vm.applicantSearchQuery = $event
                                            },
                                            focus: function($event) {
                                              _vm.applicantSearchResults
                                                .length < 2
                                                ? _vm.applicantSearch("", 5)
                                                : function() {}
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function(data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item[
                                                            _vm
                                                              .AanvragerModelKeyEnum
                                                              .NAME
                                                          ]
                                                        ) +
                                                        " | " +
                                                        _vm._s(
                                                          data.item[
                                                            _vm
                                                              .AanvragerModelKeyEnum
                                                              .EMAIL
                                                          ]
                                                        ) +
                                                        " | " +
                                                        _vm._s(
                                                          data.item[
                                                            _vm
                                                              .AanvragerModelKeyEnum
                                                              .PHONE
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2052732573
                                          ),
                                          model: {
                                            value: _vm.applicantSelected,
                                            callback: function($$v) {
                                              _vm.applicantSelected = $$v
                                            },
                                            expression: "applicantSelected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                false
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            clearable: "",
                                            items:
                                              _vm.intermediarySearchResults,
                                            "item-text":
                                              _vm.AanvragerModelKeyEnum.NAME,
                                            "item-value":
                                              _vm.AanvragerModelKeyEnum.ID,
                                            label: "Intermediair",
                                            "no-data-text":
                                              "Geen intermediairs gevonden",
                                            "return-object": "",
                                            "search-input":
                                              _vm.intermediarySearchQuery
                                          },
                                          on: {
                                            "update:searchInput": function(
                                              $event
                                            ) {
                                              _vm.intermediarySearchQuery = $event
                                            },
                                            "update:search-input": function(
                                              $event
                                            ) {
                                              _vm.intermediarySearchQuery = $event
                                            },
                                            focus: function($event) {
                                              _vm.intermediarySearchResults
                                                .length < 2
                                                ? _vm.intermediarySearch("", 5)
                                                : function() {}
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function(data) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item[
                                                            _vm
                                                              .AanvragerModelKeyEnum
                                                              .NAME
                                                          ]
                                                        ) +
                                                        " | " +
                                                        _vm._s(
                                                          data.item[
                                                            _vm
                                                              .AanvragerModelKeyEnum
                                                              .EMAIL
                                                          ]
                                                        ) +
                                                        " | " +
                                                        _vm._s(
                                                          data.item[
                                                            _vm
                                                              .AanvragerModelKeyEnum
                                                              .PHONE
                                                          ]
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2052732573
                                          ),
                                          model: {
                                            value: _vm.intermediarySelected,
                                            callback: function($$v) {
                                              _vm.intermediarySelected = $$v
                                            },
                                            expression: "intermediarySelected"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    {
                      staticClass: "profileContainer",
                      attrs: { row: "", wrap: "", "align-center": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.isUpdating
                            ? _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "50px"
                                }
                              })
                            : _vm._e(),
                          !_vm.isUpdating
                            ? _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.canClickSubmitUpdate,
                                    small: "true",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.updateReport($event)
                                    }
                                  }
                                },
                                [_vm._v(" Opslaan ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm.isCreatingReport
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isCreatingReport,
                callback: function($$v) {
                  _vm.isCreatingReport = $$v
                },
                expression: "isCreatingReport"
              }
            },
            [
              _c("CreateReportDialog", {
                attrs: { report: _vm.report },
                on: { reload: _vm.reload },
                model: {
                  value: _vm.isCreatingReport,
                  callback: function($$v) {
                    _vm.isCreatingReport = $$v
                  },
                  expression: "isCreatingReport"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }