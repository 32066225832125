import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { TotalRepairs } from '@/views/Reports/SchadeBedrag/SchadeBedrag';

@Component<ReportFinanceInfo>({})
export default class ReportFinanceInfo extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  @Prop()
  protected report!: Report;

  protected total_repairs: TotalRepairs | null = null;

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }

    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  protected mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    await this.loadTotalRepairs();
    this.isLoading = false;
  }

  protected async loadTotalRepairs() {
    const reportCopy = await new Report()
      .include(['total_repairs'])
      .find(this.report.uuid)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.total_repairs = reportCopy.total_repairs;
  }
}
