var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional profile" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              {
                staticClass: "profileContainer",
                class: { editing: _vm.isEditing },
                attrs: { row: "", wrap: "" }
              },
              [
                !_vm.reportRoles.is_offline &&
                !_vm.isEditing &&
                _vm.allowEdit &&
                !_vm.syncing
                  ? _c("div", { staticClass: "iconContainer" }, [
                      _c(
                        "span",
                        { staticClass: "iconContainer__edit" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              staticClass: "edit-icon",
                                              on: {
                                                click: function($event) {
                                                  _vm.isEditing = true
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [_vm._v("edit")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1471541473
                              )
                            },
                            [_c("span", [_vm._v("Dossier rollen aanpassen")])]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.isEditing
                  ? _c(
                      "div",
                      { staticClass: "iconContainer" },
                      [
                        !_vm.isUpdating
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "cancel-icon",
                                on: {
                                  click: function($event) {
                                    return _vm.cancelEditReportRoles()
                                  }
                                }
                              },
                              [_vm._v("cancel")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c("v-flex", [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("Rollen")
                  ])
                ])
              ],
              1
            ),
            !_vm.isEditing
              ? [
                  _c(
                    "v-layout",
                    { staticClass: "profileContainer" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { sm12: "" } },
                        [
                          _vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { sm12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Expertisebureau")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.organization
                                            ? _vm.reportRoles.organization.name
                                            : "-"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Naam opnemer")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.reportRoles.organization_expert
                                              ? _vm.reportRoles
                                                  .organization_expert.name
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _vm.reportRoles.organization_expert
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles
                                                    .organization_expert.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reportRoles
                                                      .organization_expert.phone
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Technisch coordinator")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.organization_tc
                                            ? _vm.reportRoles.organization_tc
                                                .name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.organization_tc
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles
                                                    .organization_tc.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles
                                                    .organization_tc.phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Dossierbeheerder")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.report_handler
                                            ? _vm.reportRoles.report_handler
                                                .name
                                            : "-"
                                        )
                                      )
                                    ])
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Zaakbegeleider")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.mediator
                                            ? _vm.reportRoles.mediator.name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.mediator
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles.mediator.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles.mediator.phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Juridische Zaken")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.jz_user
                                            ? _vm.reportRoles.jz_user.name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.jz_user
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles.jz_user.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles.jz_user.phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("v-flex", { attrs: { sm12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Opnemer")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.expert
                                            ? _vm.reportRoles.expert.name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.expert
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles.expert.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles.expert.phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _c(
                                        "span",
                                        { staticStyle: { display: "block" } },
                                        [_vm._v("Deskundige")]
                                      ),
                                      _c("span", [
                                        _vm._v("(indien anders dan de opnemer)")
                                      ])
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.second_expert
                                            ? _vm.reportRoles.second_expert.name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.second_expert
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles.second_expert
                                                    .phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles.second_expert
                                                    .phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Zaakbegeleider")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.mediator
                                            ? _vm.reportRoles.mediator.name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.mediator
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles.mediator.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles.mediator.phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Voorcontroleur")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.reportRoles.pre_controller
                                            ? _vm.reportRoles.pre_controller
                                                .name
                                            : "-"
                                        )
                                      )
                                    ]),
                                    _vm.reportRoles.pre_controller
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles.pre_controller
                                                    .phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.reportRoles.pre_controller
                                                    .phone
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v("Technisch coordinator")
                                    ]),
                                    _c("div", { staticClass: "value" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.reportRoles
                                              .technisch_coordinator
                                              ? _vm.reportRoles
                                                  .technisch_coordinator.name
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]),
                                    _vm.reportRoles.technisch_coordinator
                                      ? _c("div", { staticClass: "value" }, [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "tel:" +
                                                  _vm.reportRoles
                                                    .technisch_coordinator.phone
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.reportRoles
                                                      .technisch_coordinator
                                                      .phone
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.isEditing
              ? [
                  !_vm.isUpdating
                    ? _c(
                        "v-layout",
                        { staticClass: "profileContainer" },
                        [
                          _vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.backOfficers,
                                          "search-input": _vm.backOfficerSearch,
                                          label: "Dossierbeheerder",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.backOfficerSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.backOfficerSearch = $event
                                          },
                                          keyup: _vm.debouncedBackOfficerSearch
                                        },
                                        model: {
                                          value: _vm.selectedBackOfficer,
                                          callback: function($$v) {
                                            _vm.selectedBackOfficer = $$v
                                          },
                                          expression: "selectedBackOfficer"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.mediators,
                                          "search-input": _vm.mediatorSearch,
                                          label: "Zaakbegeleider",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": ""
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.mediatorSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.mediatorSearch = $event
                                          },
                                          keyup: _vm.debouncedMediatorSearch
                                        },
                                        model: {
                                          value: _vm.selectedMediator,
                                          callback: function($$v) {
                                            _vm.selectedMediator = $$v
                                          },
                                          expression: "selectedMediator"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.$store.state.Auth.hasRole(
                                    _vm.userRoles.VeldwerkRoles
                                  )
                                    ? _c(
                                        "v-flex",
                                        { attrs: { sm12: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.JZUsers,
                                              "item-value": "uuid",
                                              "item-text": "name",
                                              "hide-details": "",
                                              label: "Juridische Zaken",
                                              placeholder:
                                                "Zoek een Medewerker",
                                              "search-input": _vm.JZUserSearch
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                _vm.JZUserSearch = $event
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                _vm.JZUserSearch = $event
                                              },
                                              keyup: _vm.debouncedSearchJZUsers
                                            },
                                            model: {
                                              value: _vm.selectedJZUser,
                                              callback: function($$v) {
                                                _vm.selectedJZUser = $$v
                                              },
                                              expression: "selectedJZUser"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "with-border",
                                        attrs: {
                                          items: _vm.experts,
                                          "search-input": _vm.expertSearch,
                                          label: "Opnemer",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          disabled: !_vm.canEditExpert
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.expertSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.expertSearch = $event
                                          },
                                          keyup: _vm.debouncedSearch
                                        },
                                        model: {
                                          value: _vm.selectedExpert,
                                          callback: function($$v) {
                                            _vm.selectedExpert = $$v
                                          },
                                          expression: "selectedExpert"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "with-border",
                                        attrs: {
                                          items: _vm.secondExperts,
                                          "search-input":
                                            _vm.secondExpertSearch,
                                          label:
                                            "Deskundige (indien anders dan de opnemer)",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          disabled: !_vm.canEditExpert
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.secondExpertSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.secondExpertSearch = $event
                                          },
                                          keyup: _vm.debouncedSecondExpertSearch
                                        },
                                        model: {
                                          value: _vm.selectedSecondExpert,
                                          callback: function($$v) {
                                            _vm.selectedSecondExpert = $$v
                                          },
                                          expression: "selectedSecondExpert"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "with-border",
                                        attrs: {
                                          items: _vm.preControllers,
                                          "search-input":
                                            _vm.preControllerSearch,
                                          label: "Voorcontroleur",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          disabled: !_vm.canEditExpert
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.preControllerSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.preControllerSearch = $event
                                          },
                                          keyup:
                                            _vm.debouncedPreControllerSearch
                                        },
                                        model: {
                                          value: _vm.selectedPreController,
                                          callback: function($$v) {
                                            _vm.selectedPreController = $$v
                                          },
                                          expression: "selectedPreController"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "with-border",
                                        attrs: {
                                          items: _vm.technischCoordinators,
                                          "search-input":
                                            _vm.technischCoordinatorSearch,
                                          label: "Technisch coördinator",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          disabled: !_vm.canEditExpert
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.technischCoordinatorSearch = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.technischCoordinatorSearch = $event
                                          },
                                          keyup:
                                            _vm.debouncedTechnischCoordinatorSearch
                                        },
                                        model: {
                                          value:
                                            _vm.selectedTechnischCoordinator,
                                          callback: function($$v) {
                                            _vm.selectedTechnischCoordinator = $$v
                                          },
                                          expression:
                                            "selectedTechnischCoordinator"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEditing
                    ? _c(
                        "v-layout",
                        {
                          staticClass: "profileContainer",
                          attrs: { row: "", wrap: "", "align-center": "" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.isUpdating
                                ? _c("LoaderCard", {
                                    attrs: {
                                      flat: "",
                                      type: "spinner--center",
                                      minHeight: "50px"
                                    }
                                  })
                                : _vm._e(),
                              !_vm.isUpdating
                                ? _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        small: "true",
                                        outline: "true"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.updateReport($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Opslaan ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }