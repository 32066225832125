/* eslint-disable guard-for-in */
/* eslint-disable no-sequences */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Report, Answer, WorkFlows, WorkFlow } from '@/models/Report';
import { ReportType } from '@/models/ReportType';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { userRoles } from '@/models/User';
import WorkFlowFilter from '@/components/filters/work-flow-filter/WorkFlowFilter';
import ManagementClassFilter from '@/components/filters/management-class-filter/ManagementClassFilter';
import { getStatusColor, getStatusLabel } from '@/support/ReportStatus';
import { cloneDeep, debounce } from 'lodash';
import CreateReportDialog from '@/components/report-info/CreateReportDialog.vue';
import { disableEditor } from '@/support/ReportEditor';
import { PermissionSlug } from '@/support/PermissionSlug';
import { Aanvrager, AanvragerModelKeyEnum } from '@/models/Aanvrager';
import { ObjectionOpnameVariants, OpnameVariantEnum, OpnameVariantLabels } from '@/items/OpnameVariant';

@Component<ReportInfo>({
  components: {
    CreateReportDialog,
  },
})
export default class ReportInfo extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  protected isReportTypeChanged = false;

  protected isCreatingReport = false;

  protected isUpdatingPlanningStatus = false;

  @Prop()
  protected report!: Report;

  protected reportInfo: Report | null = null;

  protected userRoles = userRoles;

  protected generalKeys: GeneralKey = GeneralKeys;

  protected databaseDate = '0000-00-00 00:00:00';

  protected reportTypes: ReportType[] = [];

  protected WorkFlows: WorkFlow[] = [];

  protected vatNumbers: object[] = [
    { label: '6%', value: 6 },
    { label: '9%', value: 9 },
  ];

  protected AanvragerModelKeyEnum = AanvragerModelKeyEnum;

  protected applicantSelected: null | Aanvrager = null;

  protected applicantSearchQuery = '';

  protected applicantSearchResults: Aanvrager[] = [];

  protected applicantSearchDebounced = debounce(this.applicantSearch, 300);

  protected intermediarySelected: null | Aanvrager = null;

  protected intermediarySearchQuery = '';

  protected intermediarySearchResults: Aanvrager[] = [];

  protected intermediarySearchDebounced = debounce(this.intermediarySearch, 300);

  // If it's a Bezwaar report show only ObjectionOpnameVariants, else show all Opname Variants
  protected opnameVariantItems: {name: string, value: string}[] = this.report.isBezwaarAdviesCommissieReport || this.report.isBezwaarAmbtelijkReport
    ? ObjectionOpnameVariants.map((variant: string) => ({ name: OpnameVariantLabels[`${variant}`], value: variant }))
    : Object.values(OpnameVariantEnum).map((variant: string) => ({ name: OpnameVariantLabels[`${variant}`], value: variant }));

  @Watch('reportInfo.type')
  protected reportTypeChanged() {
    if (this.isEditing) {
      this.isReportTypeChanged = true;
    }
  }

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }

    if (this.$store.state.Auth.hasRole('expert') && this.report.archive_info && this.report.archive_info.is_archived_for_expert) {
      return false;
    }

    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  protected mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    this.cloneReport();
    await this.fetchReportTypes();
    this.WorkFlows = WorkFlows;
    this.setGeneralInfo();

    this.isLoading = false;
  }

  protected initializeApplicantAndIntermediary(): void {
    if (this.report.applicant) {
      this.applicantSelected = cloneDeep(this.report.applicant);
      this.applicantSearchResults = [this.applicantSelected];
    }
    if (this.report.intermediary) {
      this.intermediarySelected = cloneDeep(this.report.intermediary);
      this.intermediarySearchResults = [this.intermediarySelected];
    }
  }

  protected resetApplicant() {
    this.applicantSelected = null;
    this.applicantSearchResults = [];
  }

  protected cloneReport() {
    this.initializeApplicantAndIntermediary();
    this.isUpdatingPlanningStatus = false;
    this.reportInfo = cloneDeep(this.report);
  }

  protected async fetchReportTypes() {
    this.reportTypes = await new ReportType()
      .filter({
        is_creatable: true,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected updateReport() {
    if (! this.reportInfo) {
      return;
    }

    this.isUpdating = true;

    const payload: any = {
      calculation_model_vat_low: this.reportInfo.calculation_model_vat_low,
      planning_workflow: this.reportInfo.planning_workflow,
      is_prio: this.reportInfo.is_prio,
      is_special: this.reportInfo.is_special,
      is_zetting_zakking: this.reportInfo.is_zetting_zakking,
      is_test: this.reportInfo.is_test,
      workflow: this.reportInfo.workflow,
      management_info_class: this.reportInfo.management_info_class,
      opname_variant: this.reportInfo.opname_variant,
      applicant: this.applicantSelected?.[AanvragerModelKeyEnum.ID],
      intermediary: this.intermediarySelected?.[AanvragerModelKeyEnum.ID],
    };

    if (this.isUpdatingPlanningStatus) {
      // eslint-disable-next-line no-unused-expressions
      payload.status = this.reportInfo.status,
      payload.planning = {
        status: 'no_action_required',
      };
    }

    this.reportInfo.update(payload)
      .then(() => {
        if (this.isReportTypeChanged) {
          this.updateReportType();
        } else {
          this.isUpdating = false;
          this.isEditing = false;
          this.$emit('reloadReport');
        }
      })
      .catch((error: AxiosError) => {
        this.isUpdating = false;
        ErrorHandler.network(error);
      });
  }

  protected updateReportType() {
    if (! this.reportInfo) {
      return;
    }

    const payload = {
      report_type: this.reportInfo.type && this.reportInfo.type.uuid ? this.reportInfo.type.uuid : this.reportInfo.type,
    };

    this.reportInfo.convertType(this.report.uuid, payload)
      .then(() => {
        this.$emit('reloadReport');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isUpdating = false;
        this.isEditing = false;
      });
  }

  protected setGeneralInfo() {
    if (! this.reportInfo) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const key in this.generalKeys) {
      this.generalKeys[key].value = '';
    }

    const keys = Object.keys(this.generalKeys);
    if (! this.reportInfo.answers) {
      return;
    }

    this.reportInfo.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  protected updatePlanningStatus() {
    if (this.reportInfo) {
      this.isUpdatingPlanningStatus = true;
      this.reportInfo.planning_status = 'no_action_required';
    }
  }

  protected reload() {
    this.$emit('reloadReport');
  }

  // Helpers
  protected getStatusColor(key: string) {
    return getStatusColor(key);
  }

  protected getStatusLabel(key: string) {
    return getStatusLabel(key);
  }

  protected getVatText() {
    if (! this.report || ! this.report.calculation_model_vat_low) {
      return '';
    }

    return `met ${this.report.calculation_model_vat_low}% BTW`;
  }

  protected async applicantSearch(query: string, limit = 50): Promise<void> {
    let applicants: null | Aanvrager[] = await new Aanvrager()
      .filter('search', query)
      .limit(limit)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (! applicants) { return; }

    if (this.applicantSelected && ! applicants.some((applicant) => applicant.id === this.applicantSelected?.id)) {
      applicants = [this.applicantSelected, ...applicants];
    }

    this.applicantSearchResults = applicants;
  }

  protected async intermediarySearch(query: string, limit = 50): Promise<void> {
    let intermediaries: null | Aanvrager[] = await new Aanvrager()
      .filter('search', query)
      .limit(limit)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (! intermediaries) { return; }

    if (this.intermediarySelected && ! intermediaries.some((intermediary) => intermediary.id === this.intermediarySelected?.id)) {
      intermediaries = [this.intermediarySelected, ...intermediaries];
    }

    this.intermediarySearchResults = intermediaries;
  }

  // Getters
  protected get allowReportTypeEdit(): boolean {
    if (! this.$store.state.Auth) {
      return true;
    }

    if (this.$store.state.isServiceOrganization) {
      const allowedRoles = [
        ...this.userRoles.WVRoles,
        ...this.userRoles.PlanningRoles,
      ];
      return this.$store.state.Auth.hasRole(allowedRoles) && disableEditor('werkvoorbereiding', this.report, this.$store.state.Auth);
    }
    return this.$store.state.Auth.hasRole((this.userRoles as any).ManagerRoles) && disableEditor('technisch_coordinator', this.report, this.$store.state.Auth);
  }

  protected get canToggleIsFinalPermission(): boolean {
    if (! this.allowEdit) { return false; }

    if (this.$store.state.isServiceOrganization) { return false; }

    if (! this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles)) { return false; }

    const allowedStatuses: string[] = [
      // REGULIER - (Externe) opnemer
      'opname_in_progress', // In opname
      'opname_rejected', // Opname afgekeurd
      // REGULIER - Bureau
      'planning_organization', // Planning Expertisebureau
      'opname_done', // Opname afgerond
      'in_progress', // Lopend
      'rejected', // Afgekeurd
      'submitted', // Voorcontrole
      'checking', // Te controleren
      'veldwerk_rejected', // Afgekeurd Schadebepaling
      // NADER ADVIES - Bureau
      'zienswijze_in_progress', // Herzien rapport Lopend
      'zienswijze_rejected', // Herzien rapport Afgekeurd
      'zienswijze_checking', // Herzien rapport Te controleren
      'zienswijze_veldwerk_rejected', // Herzien rapport Afgekeurd schadebepaling
      // BEZWAAR - Bureau
      'objection_in_progress', // Bezwaar Lopend
      'objection_rejected', // Bezwaar afgekeurd
      'objection_checking', // Bezwaar Te Controleren
      'objection_veldwerk_rejected', // Bezwaar Afgekeurd Schadebepaling
      // STUWMEER - Bureau
      'smr_in_progress', // SMR Lopend
      'smr_rejected', // SMR Afgekeurd
      'smr_checking', // SMR Te Controleren
      'smr_veldwerk_rejected', // SMR Afgekeurd Schadebepaling
    ];

    if (! allowedStatuses.includes(this.report.status ? this.report.status : '')) {
      return false;
    }

    return true;
  }

  protected get hasHeropnameStatus() {
    return this.$store.state.Auth.hasRole(this.userRoles.AdminRoles) && this.isEditing && this.reportInfo?.hasPlanningStatus([
      'vervolgopname',
      'nader_onderzoek',
      'heropname',
      'aanvullend_onderzoek',
      'unplanned',
      'no_answer',
      'planned',
      'not_needed_anymore',
    ]);
  }

  protected get workflowItems() {
    return new WorkFlowFilter().items;
  }

  protected get managementInfoClassItems() {
    return new ManagementClassFilter().items;
  }

  protected get canReadProjects(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.PROJECTS_READ);
  }

  protected get canClickSubmitUpdate(): boolean {
    if (this.applicantSelected) {
      return !! this.applicantSelected?.[AanvragerModelKeyEnum.ID];
    }

    return true;
  }

  @Watch('applicantSearchQuery')
  protected applicantSearchQueryChanged(newValue: string, oldValue: string) {
    if (newValue && newValue !== oldValue) {
      this.applicantSearchDebounced(newValue);
    }
  }

  @Watch('intermediarySearchQuery')
  protected intermediarySearchQueryChanged(newValue: string, oldValue: string) {
    if (newValue && newValue !== oldValue) {
      this.intermediarySearchDebounced(newValue);
    }
  }
}
