import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';

@Component<ReportRiskProfile>({})
export default class ReportRiskProfile extends Vue {
  @Prop({ default: false })
  protected isLoading!: boolean;

  @Prop()
  protected report!: Report;

  protected mounted() {
    //
  }
}
