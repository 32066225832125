import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import ReportInfo from '@/components/report-info/ReportInfo.vue';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { Report as ReportModel } from '@/models/Report';
import { AlertBar } from '@/support/AlertBar';
import { Options } from '@/components/mi-dialog/MiDialog';
import { PusherService } from '@/support/PusherService';
import { ReportSyncStarted, ReportSyncFinished, ReportSyncFailed } from '@/support/Pusher/ReportSync';
import { Application } from '@/support/Application';
import { Route } from 'vue-router';
import { userReportTabs, ReportTab, userRoles } from '@/models/User';
import ReportRiskProfile from '@/components/report-risk-profile/ReportRiskProfile.vue';

// #region This section is used inside <component v-bind:is=""/>
import ReportTagsSkills from '@/components/report-tags-skills/ReportTagsSkills.vue';
import ReportRoles from '@/components/report-roles/ReportRoles.vue';
import ReportEditors from '@/components/report-editors/ReportEditors.vue';
import ReportDeadline from '@/components/report-deadline/ReportDeadline.vue';
import ReportDates from '@/components/report-dates/ReportDates.vue';
import ReportFinanceInfo from '@/components/report-finance-info/ReportFinanceInfo.vue';
import ReportFtrInfo from '@/components/report-ftr-info/ReportFtrInfo.vue';
import ReportStatisticsInfo from '@/components/report-statistics-info/ReportStatisticsInfo.vue';
// #endregion

import { UserPreferenceEnum, userPreferenceProfileDefaults } from '@/models/UserPreference';
import { BasicPreference, BasicPreferenceHelper, fetchUserPreferences, getUserPreference } from '@/support/UserPreferencesHelper';

@Component<Report>({
  components: {
    ReportInfo,
    ReportRiskProfile,

    // #region This section is used inside <component v-bind:is=""/>, do not rename
    'report-tags-skills': ReportTagsSkills,
    'report-roles': ReportRoles,
    'report-editors': ReportEditors,
    'report-deadline': ReportDeadline,
    'report-dates': ReportDates,
    'report-finance-info': ReportFinanceInfo,
    'report-statistics-info': ReportStatisticsInfo,
    'report-ftr-info': ReportFtrInfo,
    // #endregion
  },
})
export default class Report extends Vue {
  public $pageTitle = 'Dossier';

  // Report
  protected report: ReportModel | null = null;

  protected isLoading = true;

  // syncing
  protected isSyncing = false;

  protected generalKeys: GeneralKey = GeneralKeys;

  protected databaseDate = '0000-00-00 00:00:00';

  protected currentPath = '';

  protected activeTab = '';

  protected userRoles = userRoles;

  protected reportTabs: ReportTab[] = userReportTabs;

  protected userPreferences: UserPreferences = {
    [UserPreferenceEnum.PROFILE]: [],
  };

  protected UserPreferenceEnum = UserPreferenceEnum;

  protected async mounted() {
    await this.initialize();
  }

  protected async initialize() {
    await this.fetchReport();
    await this.fetchUserPreferences();
    this.setAlert();
    this.setActiveTab();
    this.initializePusher();
    this.initBreadcrumb();

    this.isLoading = false;
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Alle rapporten', path: '/reports' }, { name: this.report ? this.report.case_number : '' }],
    });
  }

  protected isSidebarItemVisible(preference: BasicPreference): boolean {
    if (
      this.report && this.report.isVesNulmetingReport
      && ['report-statistics-info', 'report-finance-info'].includes(`${preference.key}`)
    ) {
      return false;
    }

    return true;
  }

  protected async fetchReport() {
    if (! this.$route.params.id) {
      return;
    }

    this.report = null;

    this.report = await new ReportModel()
      .include(['applicant', 'intermediary', 'answers', 'calculation_model', 'tags', 'skills', 'organization', 'statistics', 'jz_user', 'risk_profile', 'job_project', 'project_media', 'archive_info', 'applicant_submissions'])
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchUserPreferences(): Promise<void> {
    const userPreferences = await fetchUserPreferences();

    const userPreferencesProfile = getUserPreference(userPreferences || [], UserPreferenceEnum.PROFILE);
    this.userPreferences[UserPreferenceEnum.PROFILE] = BasicPreferenceHelper.getInstance().getCleanPreferences(userPreferencesProfile || [], userPreferenceProfileDefaults);
  }

  // Set active tab
  protected setActiveTab() {
    const urlParts = window.location.pathname.split('/');
    this.currentPath = `/${urlParts[1]}/${urlParts[2]}`;

    const user = this.$store.state.Auth;

    if (! urlParts[3]) {
      if (
        ! this.reportTabs.some((tab: ReportTab) => tab.value === user.default_tab)
      ) {
        this.$router.push(`${this.currentPath}/communicatie`);
        return;
      }

      if (user && user.default_tab && user.default_tab.length) {
        this.$router.push(`${this.currentPath}/${user.default_tab}`);
      } else {
        this.$router.push(`${this.currentPath}/communicatie`);
      }
    } else {
      this.activeTab = window.location.pathname;
    }
  }

  // Set alert bar
  protected setAlert() {
    if (this.report && this.report.isOffline()) {
      const alert = {
        message: AlertBar.setOfflineMessage(this.report),
        show: true,
      };

      this.$store.dispatch('updateAlert', alert);
    }
  }

  protected get isServiceLoket(): boolean {
    return this.$store.state.Auth.hasRole('serviceloket');
  }

  protected get allowEdit() {
    if (! this.report) {
      return false;
    }

    if (this.report.isClosed) {
      return false;
    }

    if (this.$store.state.Auth.hasRole('expert') && this.report.archive_info && this.report.archive_info.is_archived_for_expert) {
      return false;
    }

    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  protected get tabs(): Tab[] {
    return [
      {
        name: 'Communicatie',
        key: 'communicatie',
        href: '/communicatie',
        visible: () => true,
      },
      {
        name: 'Beheer',
        key: 'beheer',
        href: '/beheer',
        visible: () => this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles),
      },
      {
        name: 'Planning',
        key: 'planning',
        href: '/planning',
        visible: () => true,
      },
      {
        name: 'Urenadministratie',
        key: 'urenadministratie',
        href: '/urenadministratie',
        visible: () => ! this.$store.state.isServiceOrganization && this.$store.state.hasTimeModule,
      },
      {
        name: 'Schades',
        key: 'schades',
        href: '/schades',
        visible: () => true,
      },
      {
        name: 'Management Info',
        key: 'management-info',
        href: '/management-info',
        visible: () => ! this.isServiceLoket,
      },
      {
        name: 'Zelfopname App',
        key: 'msr',
        href: '/mijn-schaderegelen',
        visible: () => this.report?.isZelfopnameReport || this.report?.applicant_submissions,
      },
      {
        name: 'Historische schades',
        key: 'historische-schades',
        href: '/historische-schades',
        visible: () => ! this.report?.isVesNulmetingReport,
      },
      {
        name: 'Trillingstool',
        key: 'trillingstool-realtime',
        href: '/trillingstool-realtime',
        visible: () => ! this.isServiceLoket && ! this.report?.isVesNulmetingReport,
      },
      {
        name: 'Bijlagen',
        key: 'bijlagen',
        href: '/bijlagen',
        visible: () => ! this.isServiceLoket,
      },
      {
        name: 'Schadebedrag',
        key: 'schadebedrag',
        href: '/schadebedrag',
        visible: () => ! this.isServiceLoket && ! this.report?.isVesNulmetingReport,
      },
      {
        name: 'Vragen overzicht',
        key: 'vragenoverzicht',
        href: '/vragenoverzicht',
        visible: () => ! this.isServiceLoket,
      },
      {
        name: 'Data Quality',
        key: 'data-quality',
        href: '/data-quality',
        visible: () => this.$store.state.isServiceOrganization && this.$store.state.Auth.hasRole(this.userRoles.AdminRoles) && ! this.report?.isOwnedByBureau,
      },
    ];
  }

  // PUSHER
  /**
   * Initalize Pusher room and listen for events
   */
  private pusher: PusherService | null = null;

  private initializePusher() {
    if (! Application.config('APP_APPLICATION_MODE') || ! this.$store.state.Auth) {
      return;
    }

    this.pusher = new PusherService()
      .author(this.$store.state.Auth)
      .room(Application.config('APP_APPLICATION_MODE') as string)
      .echo()
      .on('report:sync.started', (event: ReportSyncStarted) => {
        // console.log('[PUSHER] report:sync.started');
        this.handleSyncStarted(event.report);
      })
      .on('report:sync.finished', (event: ReportSyncFinished) => {
        // console.log('[PUSHER] report:sync.finished', event);
        this.handleSyncFinished(event.report);
      })
      .on('report:sync.failed', (event: ReportSyncFailed) => {
        // console.log('[PUSHER] report:sync.failed', event);
        this.handleSyncFailed(event.report, event.error);
      })
      .on('pusher:subscription_succeeded', (member: any) => {
        // console.log('[PUSHER] pusher:subscription_succeeded');
      })
      .on('pusher:subscription_error', (response: any) => {
        // console.log('[PUSHER] rpusher:subscription_error');
      });
  }

  private destroyed() {
    if (! this.pusher || ! Application.config('APP_APPLICATION_MODE')) {
      return;
    }

    this.pusher.unsubscribe(Application.config('APP_APPLICATION_MODE') as string);
  }

  private handleSyncStarted(syncReport: ReportModel) {
    if (this.report && syncReport.id !== this.report.uuid) {
      return;
    }

    this.isSyncing = true;
    this.$root.$emit('syncReport', true);
  }

  private handleSyncFinished(syncReport: ReportModel) {
    if (this.report && syncReport.id !== this.report.uuid) {
      return;
    }

    this.isSyncing = false;
    this.$root.$emit('syncReport', false);
    this.$store.dispatch('openDialog', this.dialogOptionsSuccessSync);
  }

  private handleSyncFailed(syncReport: ReportModel, error: string) {
    if ((this.report && syncReport.id !== this.report.uuid) || ! error) {
      return;
    }

    this.isSyncing = false;
    this.$root.$emit('syncReport', false);

    // show error dialog
    this.$store.dispatch('openDialog', this.dialogOptionsErrorSync);
  }

  private get dialogOptionsSuccessSync(): Options {
    return {
      title: 'Bevestiging',
      text: 'Het synchroniseren van het dossier is gelukt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  private get dialogOptionsErrorSync(): Options {
    return {
      title: 'Error',
      text: 'Het synchroniseren van het dossier is mislukt, probeer het nogmaals of neem contact op met de helpdesk.',
      type: 'error',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }

  @Watch('$route')
  protected routeChanged(to: Route, from: Route) {
    if (from.params.id !== to.params.id) {
      this.initialize();
      return;
    }

    const fromUrlParts = from.fullPath.split('/');
    const toUrlParts = to.fullPath.split('/');

    if (fromUrlParts.length === 4 && toUrlParts.length === 3) {
      this.$router.back();
    }

    this.setAlert();
  }
}

export interface Tab {
  name: string;
  key: string;
  href: string;
  visible: Function;
  click?: Function;
}

export interface Panel {
  [key: number]: boolean;
}

export interface FileType {
  label: string;
  value: string;
}

interface UserPreferences {
  [UserPreferenceEnum.PROFILE]: BasicPreference[];
}
