var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            [
              _c(
                "v-layout",
                {
                  staticClass: "profileContainer",
                  class: { editing: _vm.isEditing },
                  attrs: { row: "", wrap: "" }
                },
                [
                  !_vm.isEditing &&
                  !_vm.$store.state.isServiceOrganization &&
                  _vm.$store.state.Auth.hasRole([
                    "admin",
                    "manager",
                    "expert"
                  ]) &&
                  _vm.allowEdit &&
                  !_vm.syncing &&
                  !_vm.report.is_offline
                    ? _c("div", { staticClass: "iconContainer" }, [
                        _c(
                          "span",
                          { staticClass: "iconContainer__edit" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "edit-icon",
                                                on: {
                                                  click: function($event) {
                                                    _vm.isEditing = true
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("edit")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1471541473
                                )
                              },
                              [_c("span", [_vm._v("Dossier datums aanpassen")])]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.isEditing
                    ? _c(
                        "div",
                        { staticClass: "iconContainer" },
                        [
                          !_vm.isUpdating
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "cancel-icon",
                                  on: {
                                    click: function($event) {
                                      _vm.isEditing = false
                                      _vm.cancelReportDates()
                                    }
                                  }
                                },
                                [_vm._v(" cancel ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("span", { staticClass: "profile__userName" }, [
                      _vm._v("Datum")
                    ])
                  ])
                ],
                1
              )
            ],
            !_vm.isEditing
              ? [
                  _c(
                    "v-layout",
                    {
                      staticClass: "profileContainer",
                      attrs: { row: "", wrap: "", "align-center": "" }
                    },
                    [
                      _c("v-flex", { attrs: { sm12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Aangemaakt")
                        ]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  _vm.reportDates.created_at ===
                                    _vm.databaseDate
                                    ? "-"
                                    : _vm.reportDates.created_at
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { sm12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Opnamedatum")
                        ]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  _vm.reportDates.planned_at ===
                                    _vm.databaseDate
                                    ? "-"
                                    : _vm.reportDates.planned_at
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { sm12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Door deskundige ingeleverd")
                        ]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  _vm.reportDates.submitted_at ===
                                    _vm.databaseDate
                                    ? "-"
                                    : _vm.reportDates.submitted_at
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { sm12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Voorcontrole uitgevoerd")
                        ]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  _vm.reportDates.pre_controlled_at ===
                                    _vm.databaseDate
                                    ? "-"
                                    : _vm.reportDates.pre_controlled_at
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-flex", { attrs: { sm12: "" } }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("Door 4-ogen ondertekend")
                        ]),
                        _c("div", { staticClass: "value disabled" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateFormatTime")(
                                  _vm.reportDates.approved_at ===
                                    _vm.databaseDate
                                    ? "-"
                                    : _vm.reportDates.approved_at
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _vm.$store.state.Auth.has_management_info
                        ? _c("v-flex", { attrs: { sm12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Vrijgesteld van doorlooptijd t/m")
                            ]),
                            _c("div", { staticClass: "value disabled" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormatTime")(
                                      _vm.reportDates.last_appointment_at ===
                                        _vm.databaseDate
                                        ? "-"
                                        : _vm.reportDates.last_appointment_at
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.$store.state.Auth.has_management_info
                        ? _c("v-flex", { attrs: { sm12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Rapportage Datum Contract")
                            ]),
                            _c("div", { staticClass: "value disabled" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormatTime")(
                                      _vm.reportDates.downloaded_at ===
                                        _vm.databaseDate
                                        ? "-"
                                        : _vm.reportDates.downloaded_at
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _vm.isEditing
              ? [
                  !_vm.isUpdating
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          !_vm.$store.state.isServiceOrganization
                            ? [
                                _c(
                                  "v-flex",
                                  { attrs: { sm7: "" } },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "dialog",
                                        attrs: {
                                          "close-on-content-click": false,
                                          lazy: "",
                                          "nudge-right": "100",
                                          "full-width": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "input input-date",
                                          attrs: {
                                            slot: "activator",
                                            placeholder: "DD-MM-YYYY",
                                            label: "Gepland",
                                            clearable: "",
                                            "error-messages":
                                              _vm.plannedAt.errorMessage
                                          },
                                          on: {
                                            "click:clear": function($event) {
                                              return _vm.plannedAt.clear()
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value: _vm.plannedAt.formattedDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.plannedAt,
                                                "formattedDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "plannedAt.formattedDate"
                                          }
                                        }),
                                        _c("v-date-picker", {
                                          ref: "picker",
                                          attrs: {
                                            "first-day-of-week": "1",
                                            locale: "nl-nl",
                                            min: "1910-01-01",
                                            color: "#837f16"
                                          },
                                          model: {
                                            value: _vm.plannedAt.selectedDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.plannedAt,
                                                "selectedDate",
                                                $$v
                                              )
                                            },
                                            expression: "plannedAt.selectedDate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { sm5: "" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-time",
                                      attrs: {
                                        mask: _vm.mask.time,
                                        placeholder: "HH:MM",
                                        clearable: "",
                                        "error-messages":
                                          _vm.plannedAt.errorMessageTime
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.plannedAt.clearTime()
                                        }
                                      },
                                      model: {
                                        value: _vm.plannedAt.selectedTime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.plannedAt,
                                            "selectedTime",
                                            $$v
                                          )
                                        },
                                        expression: "plannedAt.selectedTime"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { sm7: "" } },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "dialog",
                                        attrs: {
                                          "close-on-content-click": false,
                                          lazy: "",
                                          "nudge-right": "100",
                                          "full-width": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "input input-date",
                                          attrs: {
                                            slot: "activator",
                                            placeholder: "DD-MM-YYYY",
                                            label: "Door deskundige ingeleverd",
                                            clearable: "",
                                            "error-messages":
                                              _vm.submittedAt.errorMessage
                                          },
                                          on: {
                                            "click:clear": function($event) {
                                              return _vm.submittedAt.clear()
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value:
                                              _vm.submittedAt.formattedDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.submittedAt,
                                                "formattedDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "submittedAt.formattedDate"
                                          }
                                        }),
                                        _c("v-date-picker", {
                                          ref: "picker",
                                          attrs: {
                                            "first-day-of-week": "1",
                                            locale: "nl-nl",
                                            min: "1910-01-01",
                                            color: "#837f16"
                                          },
                                          model: {
                                            value: _vm.submittedAt.selectedDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.submittedAt,
                                                "selectedDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "submittedAt.selectedDate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { sm5: "" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-time",
                                      attrs: {
                                        mask: _vm.mask.time,
                                        placeholder: "HH:MM",
                                        clearable: "",
                                        "error-messages":
                                          _vm.submittedAt.errorMessageTime
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.submittedAt.clearTime()
                                        }
                                      },
                                      model: {
                                        value: _vm.submittedAt.selectedTime,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.submittedAt,
                                            "selectedTime",
                                            $$v
                                          )
                                        },
                                        expression: "submittedAt.selectedTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    {
                      staticClass: "profileContainer",
                      attrs: { row: "", wrap: "", "align-center": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.isUpdating
                            ? _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "50px"
                                }
                              })
                            : _vm._e(),
                          !_vm.isUpdating
                            ? _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "true",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.updateReport($event)
                                    }
                                  }
                                },
                                [_vm._v(" Opslaan ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }