import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<CreateReportDialog>({})
export default class CreateReportDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected caseNumber = '';

  protected isLoading = false;

  protected close() {
    this.$emit('input', false);
  }

  protected create() {
    if (! this.report || ! this.report.uuid) {
      return;
    }

    this.isLoading = true;

    const payload = {
      case_number: this.caseNumber,
    };

    new Report()
      .setBasedOnEndpoint(this.report.uuid)
      .create(payload)
      .then((report: Report) => {
        this.$router.push(`/reports/${report.uuid}/communicatie`);
        this.$emit('reload');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
}
