import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';

@Component<ReportStatisticsInfo>({})
export default class ReportStatisticsInfo extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  @Prop()
  protected report!: Report;

  protected statistics: any | null = null;

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }
    return ! this.$store.state.Auth.hasRole(['case_mediator', 'serviceloket']);
  }

  protected mounted() {
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = false;
    this.statistics = this.report.statistics;
  }
}
