var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reportContainer" },
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", md3: "" } },
                [
                  _vm.report
                    ? [
                        _c("ReportInfo", {
                          attrs: { report: _vm.report, syncing: _vm.isSyncing },
                          on: {
                            reloadReport: function($event) {
                              return _vm.fetchReport()
                            }
                          }
                        }),
                        _c("ReportRiskProfile", {
                          attrs: { report: _vm.report }
                        }),
                        _vm._l(
                          _vm.userPreferences[_vm.UserPreferenceEnum.PROFILE],
                          function(userPreference, index) {
                            return [
                              _vm.isSidebarItemVisible(userPreference)
                                ? _c(
                                    userPreference.key
                                      ? userPreference.key
                                      : userPreference,
                                    {
                                      key: index,
                                      tag: "component",
                                      attrs: {
                                        report: _vm.report,
                                        syncing: _vm.isSyncing
                                      },
                                      on: {
                                        reloadReport: function($event) {
                                          return _vm.fetchReport()
                                        }
                                      }
                                    }
                                  )
                                : _vm._e()
                            ]
                          }
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", md9: "" } },
                [
                  _vm.report
                    ? _c(
                        "v-tabs",
                        {
                          attrs: { "hide-slider": "" },
                          model: {
                            value: _vm.activeTab,
                            callback: function($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab"
                          }
                        },
                        [
                          _vm._l(_vm.tabs, function(tab, index) {
                            return [
                              tab.visible()
                                ? _c(
                                    "v-tab",
                                    {
                                      key: index,
                                      staticClass: "tab__filled",
                                      class: {
                                        active:
                                          _vm.activeTab ===
                                          "" + _vm.currentPath + tab.href
                                      },
                                      attrs: {
                                        to: "" + _vm.currentPath + tab.href
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(tab.name) + " ")]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  !_vm.isLoading && _vm.report
                    ? _c("router-view", {
                        attrs: {
                          syncing: _vm.isSyncing,
                          "allow-edit": _vm.allowEdit,
                          report: _vm.report
                        },
                        on: {
                          reload: _vm.initialize,
                          "update:report": function($event) {
                            _vm.report = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }