import { ReportTypes } from '@/support/ReportTypes';
import { Answer } from '@/models/Answer';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { SidemenuItem } from '@/components/sidemenu/Sidemenu';
import { getStatusLabel, getStatusObjects, ReportStatus } from '@/support/ReportStatus';
import { disableEditor } from '@/support/ReportEditor';
import ZienswijzeDialog from '@/components/dialog/zienswijze-dialog/ZienswijzeDialog.vue';
import ChangeStatusDialog from '@/components/dialog/change-status-dialog/ChangeStatusDialog.vue';
import BezwaarDialog from '@/components/dialog/bezwaar-dialog/BezwaarDialog.vue';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { User, UserRole, userRoles } from '@/models/User';
import { debounce } from 'lodash';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import { VesStatusEnum, VesStatusLabels } from '@/items/VesStatus';
import { OpnameVariantEnum } from '@/items/OpnameVariant';
import { WorkflowEnum } from '../filters/work-flow-filter/WorkFlowFilter';

@Component<ReportEditors>({
  components: {
    ZienswijzeDialog,
    ChangeStatusDialog,
    BezwaarDialog,
  },
})
export default class ReportEditors extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  protected isCreatingZienswijze = false;

  protected isCreatingBezwaar = false;

  // Status
  protected statusChangeDialogOptions = {};

  // opens send to specific status dialog
  protected isSendingToJz = false;

  protected isSendingToStatus = false;

  protected sendStatusDialog: SendStatusDialog = {};

  // JZ users
  protected debouncedSearchUsers: Function = debounce(this.handleSearchUser, 300);

  protected userSearch = '';

  protected selectedUser = '';

  protected users: User[] = [];

  // opens send to all status dialog
  protected isChangingStatus = false;

  @Prop()
  protected report!: Report;

  // userRoles
  protected userRoles = userRoles;

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }

    if (this.$store.state.Auth.hasRole(UserRole.EXPERT) && this.report.archive_info && this.report.archive_info.is_archived_for_expert) {
      return false;
    }

    return ! this.$store.state.Auth.hasRole([UserRole.CASE_MEDIATOR, UserRole.SERVICELOKET]);
  }

  protected async mounted() {
    this.isLoading = false;
    this.listUsers();
  }

  protected isAllowedToClick(editor: string) {
    if (! this.allowEdit || this.syncing) {
      return false;
    }

    return disableEditor(editor, this.report, this.$store.state.Auth);
  }

  protected get isObjectionReport() {
    if (this.report.isBezwaarReport) {
      return this.$store.state.Auth.hasRole(this.userRoles.ObjectionRoles);
    }
  }

  // Getters
  protected get editorClosedItems(): SidemenuItem[] {
    if (! this.report.answers) {
      return [];
    }

    const replanType = this.report.answers.find((answer: any) => {
      if (! answer.key) {
        return false;
      }

      return answer.key === 'HerplannenType';
    }) as Answer;

    const typeLabel = replanType && replanType.value ? getStatusLabel(replanType.value) : '';

    return [
      {
        title: 'Nader Advies',
        subtitle: 'Maak een verzoek tot Nader Advies aan',
        action: () => {
          this.isCreatingZienswijze = true;
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.isClosed && ! this.report.isBezwaarReport,
      },
      {
        title: 'Bezwaar Nader Advies',
        subtitle: 'Maak een verzoek tot Bezwaar Nader Advies aan',
        action: () => {
          this.isCreatingZienswijze = true;
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.isClosed && this.report.isBezwaarReport,
      },
      {
        title: '(Ambtelijk) Bezwaar Advies',
        subtitle: 'Vul het bezwaar nummer in en kies het rapport type.',
        action: () => {
          this.isCreatingBezwaar = true;
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.isClosed && ! this.report.isBezwaarReport,
      },
      {
        title: 'Heropname aanmaken',
        subtitle: 'Maak een heropname aan.',
        action: () => {
          this.$store.dispatch('openDialog', this.confirmHeropnameDialogOptions);
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.isClosed,
      },
      this.statusChangeDialogSettings,
      {
        title: 'Wijzig interesse VES',
        subtitle: 'Zet dossier terug naar planning en wijzig VES interesse naar geweigerd',
        action: () => {
          this.$store.dispatch('openDialog', this.changeVesInterestDialogOptions);
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.status === ReportStatus.PLANNING_ON_HOLD && this.report.ves_status === VesStatusEnum.INTERESTED,
      },
      {
        title: `${typeLabel} goedkeuren`,
        subtitle: `Wil je het verzoek voor een ${typeLabel} goedkeuren?`,
        action: () => {
          this.sendStatusDialog = {
            title: `${typeLabel} goedkeuren`,
            description: `Wil je het verzoek voor een ${typeLabel} goedkeuren?`,
            confirmAction: () => {
              this.updateReportToStatus(ReportStatus.BACK_TO_PLANNING, replanType && replanType.value ? replanType.value : 'unplanned');
            },
            cancelAction: () => {
              this.updateReportToStatus(this.pendingSbApprovalBackStatus, 'planned');
            },
            confirm: 'Ja, goedkeuren',
            cancel: 'Nee, afkeuren',
          };
          this.isSendingToStatus = true;
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
      },
    ];
  }

  protected get editorItems(): SidemenuItem[] {
    return [
      {
        title: 'Controle JZ',
        subtitle: 'Rapport versturen aan JZ',
        action: () => {
          this.sendStatusDialog = {
            title: 'Versturen naar JZ',
            description: 'Wil je dit rapport ter controle versturen aan JZ?',
            confirmAction: () => {
              this.updateReportToStatus(ReportStatus.SPECIALS_IN_REVIEW);
            },
            cancelAction: () => {
              this.close();
            },
            confirm: 'Ja, versturen',
            cancel: 'Annuleren',
          };
          this.isSendingToJz = true;
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.hasStatus([ReportStatus.VELDWERK_CORRECTED, ReportStatus.APPROVED, ReportStatus.VELDWERK_ONHOLD]),
      },
      {
        title: 'Controle Schade Afhandeling',
        subtitle: 'Terugsturen naar Schade Afhandeling?',
        action: () => {
          this.sendStatusDialog = {
            title: 'Versturen naar Schade Afhandeling',
            description: 'Heb je dit rapport beoordeeld en wil je dit terugsturen naar Schade Afhandeling?',
            confirmAction: () => {
              this.updateReportToStatus(ReportStatus.SPECIALS_REVIEWED);
            },
            cancelAction: () => {
              this.close();
            },
            confirm: 'Ja, versturen',
            cancel: 'Annuleren',
          };
          this.isSendingToJz = true;
        },
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.report.hasStatus([ReportStatus.SPECIALS_IN_REVIEW]) && this.$store.state.Auth.uuid === this.report.jz_user?.uuid,
      },
      {
        title: 'Nalever',
        subtitle: 'Opvoeren van rapporten',
        link: `/nalever/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.VeldwerkRoles, ...this.userRoles.WVRoles, ...this.userRoles.ManagerRoles, ...this.userRoles.ObjectionRoles]) && this.$store.state.isServiceOrganization && ! this.report.hasStatus('pending_sb_approval'),
        disabled: ! this.allowEdit || this.report.is_offline ? true : false || this.syncing,
      },
      {
        title: 'Werkvoorbereiding',
        subtitle: 'Opvoeren van rapporten',
        link: `/work-preparation/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.isAllowedToView && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('werkvoorbereiding'),
      },
      {
        title: 'Planning',
        subtitle: 'Inplannen van rapporten',
        link: `/planning-tool-immaterieel?report=${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.isServiceOrganization && (this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles) || this.isObjectionReport) && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL) && this.isImmaterial,
        disabled: ! this.isAllowedToClick('planning'),
      },
      {
        title: 'Planning',
        subtitle: 'Inplannen van rapporten',
        link: `/planning-tool-ves-nulmeting?report=${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.isServiceOrganization && (this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles) || this.isObjectionReport) && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL) && this.isVES,
        disabled: ! this.isAllowedToClick('planning'),
      },
      {
        title: 'Planning',
        subtitle: 'Inplannen van rapporten',
        link: `/planning-tool?report=${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.isServiceOrganization && (this.$store.state.Auth.hasRole(this.userRoles.PlanningRoles) || this.isObjectionReport) && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL) && ! this.isImmaterial && ! this.isVES,
        disabled: ! this.isAllowedToClick('planning'),
      },
      {
        title: 'Planning Bureau',
        subtitle: 'Instellen van opnemer, deskundige & bezoekdatum',
        link: `/planning-expert/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: () => {
          if (this.$store.state.isServiceOrganization || this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL)) {
            return false;
          }

          return this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles) || this.report.status === ReportStatus.PLANNING_ORGANIZATION || this.report.status === ReportStatus.OPNAME_IN_PROGRESS;
        },
        disabled: () => {
          if (this.report.isVesNulmetingReport) {
            return true;
          }

          if (this.report.isWoningCooperatie2Report || this.report.isWoningCooperatie2ReportNew) {
            return false;
          }

          if (this.report.opname_variant === OpnameVariantEnum.AANNEMERSVARIANT || this.report.opname_variant === OpnameVariantEnum.WOCO1 || this.report.opname_variant === OpnameVariantEnum.WOCO2) {
            return false;
          }

          if (! this.report.isAannemersReport && ! this.report.isWoningCooperatie1Report && ! this.report.isWoningCooperatie1ReportNew && this.$store.state.Auth.hasRole(UserRole.EXPERT)) {
            return true;
          }

          return ! this.isAllowedToClick('planning_expert');
        },
      },
      {
        title: 'Uitvraag IMG',
        subtitle: 'Uitvraag van IMG',
        link: `/uitvraag/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles) && ! this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('expert_requested'),
      },
      {
        title: 'VES Geschikt',
        subtitle: `Stuur dossier naar bak: ${getStatusLabel(ReportStatus.ZIENSWIJZE_ONHOLD)}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        action: () => {
          this.sendStatusDialog = {
            title: getStatusLabel(ReportStatus.ZIENSWIJZE_ONHOLD),
            description: `Verplaats het dossier naar de bak: ${getStatusLabel(ReportStatus.ZIENSWIJZE_ONHOLD)}`,
            confirmAction: () => {
              this.updateReportToStatus(ReportStatus.ZIENSWIJZE_ONHOLD);
            },
            cancelAction: () => {
              this.close();
            },
            confirm: 'Ja, verplaatsen',
            cancel: 'Annuleren',
          };
          this.isSendingToStatus = true;
        },
        visible: this.report.hasVesStatus(VesStatusEnum.SUITED) && this.$store.state.isServiceOrganization && this.report.hasStatus(ReportStatus.ZIENSWIJZE_IN_PROGRESS),
        disabled: ! this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles),
      },
      {
        title: 'VES afwijzen',
        subtitle: `Stuur dossier terug naar ${getStatusLabel(ReportStatus.ZIENSWIJZE_IN_PROGRESS)}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        action: () => {
          this.$store.dispatch('openDialog', this.rejectVesZienswijzeDialogOptions);
        },
        visible: this.report.hasVesStatus(VesStatusEnum.SUITED) && this.$store.state.isServiceOrganization && this.report.hasStatus(ReportStatus.ZIENSWIJZE_ONHOLD),
        disabled: ! this.$store.state.Auth.hasRole(this.userRoles.ManagerRoles),
      },
      {
        title: 'Deskundige',
        subtitle: 'Het opstellen van rapporten',
        link: `/expert/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.DeskundigeRoles, this.userRoles.HelpdeskTCMG]) && ! this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('deskundige'),
      },
      {
        title: 'Deskundige eenvoudig',
        subtitle: 'Het opstellen van rapporten',
        link: `/schade-regelen/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole(this.userRoles.DeskundigeRoles) && ! this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: () => {
          if (this.report.isVesNulmetingReport) {
            return true;
          }
          return ! this.isAllowedToClick('deskundige_simple');
        },
      },
      {
        title: 'Werkverdeling',
        subtitle: 'Het toewijzen van een deskundige aan opname afgerond rapport',
        link: `/work-division/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole(this.userRoles.PreControllerRoles) && ! this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('werkverdeling'),
      },
      {
        title: 'Voorcontrole',
        subtitle: 'Controleren van basis informatie rapporten',
        link: `/pre-check/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.PreControllerRoles, ...this.userRoles.HelpdeskTCMG]) && ! this.$store.state.isServiceOrganization,
        disabled: () => {
          if (this.report.isVesNulmetingReport) {
            return true;
          }
          return ! this.isAllowedToClick('voorcontrole');
        },
      },
      {
        title: 'Voorcontrole WoCo',
        subtitle: 'Controleren van basis informatie rapporten',
        link: `/pre-check-images/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole(this.userRoles.DeskundigeRoles) && ! this.$store.state.isServiceOrganization && (this.report.isWoningCooperatieReport || this.report.isWoningCooperatieReportNew) && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('voorcontrole_woco'),
      },
      {
        title: 'Technisch Coordinator',
        subtitle: 'Het akkoorderen van rapporten',
        link: `/manager/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.ManagerRoles, ...this.userRoles.HelpdeskTCMG]) && ! this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('technisch_coordinator'),
      },
      {
        title: 'Bouwkundige controle',
        subtitle: 'Het akkoorderen van afwijkingen',
        link: `/bouwkundige-controle/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.VeldwerkRoles, ...this.userRoles.ObjectionRoles]) && this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('veldwerk'),
      },
      {
        title: 'Schadebepaling',
        subtitle: 'Controleren van opgeleverde rapporten',
        link: `/schadebepaling/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.VeldwerkRoles, ...this.userRoles.ObjectionRoles]) && this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('veldwerk'),
      },
      {
        title: 'Schadebepaling On-hold',
        subtitle: 'Zet het dossier naar de on-hold bak',
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        action: () => {
          this.sendStatusDialog = {
            title: 'Schadebepaling On-hold',
            description: 'Verplaats het dossier naar de bak: Schadebepaling On-hold',
            confirmAction: () => {
              this.updateReportToStatus(ReportStatus.VELDWERK_ONHOLD);
            },
            cancelAction: () => {
              this.close();
            },
            confirm: 'Ja, verplaatsen',
            cancel: 'Annuleren',
          };
          this.isSendingToStatus = true;
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.VeldwerkRoles, ...this.userRoles.ObjectionRoles]) && this.$store.state.isServiceOrganization && this.report.hasStatus([ReportStatus.APPROVED, ReportStatus.VELDWERK_CORRECTED]),
      },
      {
        title: 'Herzien rapport On-hold ',
        subtitle: 'Zet het dossier naar de on-hold bak',
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        action: () => {
          this.sendStatusDialog = {
            title: 'Herzien rapport On-hold ',
            description: 'Verplaats het dossier naar de bak: Herzien rapport On-hold ',
            confirmAction: () => {
              this.updateReportToStatus(ReportStatus.ZIENSWIJZE_ONHOLD);
            },
            cancelAction: () => {
              this.close();
            },
            confirm: 'Ja, verplaatsen',
            cancel: 'Annuleren',
          };
          this.isSendingToStatus = true;
        },
        visible: this.$store.state.Auth.hasRole([...this.userRoles.VeldwerkRoles, ...this.userRoles.ObjectionRoles]) && this.$store.state.isServiceOrganization && this.report.hasStatus([ReportStatus.ZIENSWIJZE_APPROVED, ReportStatus.ZIENSWIJZE_CORRECTED]),
      },
      {
        title: 'Bezwaarrapporten',
        subtitle: 'Opvoeren van nieuwe bezwaarrapporten',
        link: `/work-preparation-objection/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: this.$store.state.Auth.hasRole(this.userRoles.ObjectionRoles) && this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('objection'),
      },
      {
        title: '[OUD] Planning',
        subtitle: 'Inplannen met de oude planningstool',
        link: `/planning/${this.report.uuid}`,
        icon: {
          key: 'exit_to_app',
          position: 'right',
        },
        visible: (this.$store.state.Auth.hasRole(this.userRoles.WVRoles) || this.isObjectionReport) && this.$store.state.isServiceOrganization && ! this.report.hasStatus(ReportStatus.PENDING_SB_APPROVAL),
        disabled: ! this.isAllowedToClick('planning'),
      },
    ];
  }

  protected close() {
    this.isSendingToStatus = false;
    this.isSendingToJz = false;
  }

  protected async updateReportStatusWithRpc(status: ReportStatus) {
    if (! this.report) {
      return;
    }

    const payload = {
      signature: 'report:status',
      body: {
        report: this.report.case_number,
        status,
      },
    };

    await new Rpc()
      .rpcPost(payload);
  }

  protected async updateVesStatus(status: VesStatusEnum) {
    const payload = {
      signature: 'planning:ves-decision',
      body: {
        report: this.report?.uuid,
        ves_status: status,
      },
    };

    await new Rpc()
      .rpcPost(payload);
  }

  protected async updateReportToStatus(status: ReportStatus, planning_status?: string) {
    if (planning_status === 'heropname') {
      this.createHeropname();
      return;
    }

    let payload: any = {};
    if (planning_status) {
      payload = {
        status,
        planning: {
          status: planning_status,
        },
      };
    } else {
      payload = {
        status,
      };

      if (this.selectedUser) {
        payload.jz_user = this.selectedUser;
      }
    }

    this.isLoading = true;
    await new Report(this.report).update(payload).then(() => {
      this.reloadReport();
      this.isLoading = false;
    });
  }

  protected createHeropname() {
    new Rpc()
      .rpcPost({
        signature: 'heropname:create',
        body: {
          report: this.report.case_number,
        },
      })
      .then(() => {
        this.reloadReport();
      });
  }

  protected get isAllowedToView(): boolean {
    if (! this.$store.state.isServiceOrganization) {
      return false;
    }

    if (this.$store.state.Auth.hasRole((this.userRoles as any).ObjectionRoles) && this.report.isBezwaarReport) {
      return true;
    }

    if (this.$store.state.Auth.hasRole((this.userRoles as any).VeldwerkRoles) && this.report.isZienswijzeReport) {
      return true;
    }

    return this.$store.state.Auth.hasRole((this.userRoles as any).WVRoles);
  }

  protected reloadReport() {
    this.$emit('reloadReport');
  }

  protected handleSearchUser(query: any) {
    if (! query) {
      return;
    }

    this.listUsers();
  }

  protected async listUsers(): Promise<void> {
    this.users = await new User()
      .filter({
        search: this.userSearch,
        types: [UserRole.DAMAGE_HANDLER, UserRole.DAMAGE_CONTROLLER],
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected get statusChangeDialogSettings(): SidemenuItem {
    const settings: SidemenuItem = {
      action: () => {
        this.isChangingStatus = true;
      },
      icon: {
        key: 'exit_to_app',
        position: 'right',
      },
      title: 'Pas dossier status aan',
      subtitle: 'Verplaats het dossier naar een nieuwe bak',
      visible: this.report.isProblem,
    };

    if (this.report.isProblem) {
      this.statusChangeDialogOptions = {
        title: 'Verplaatsen uit: Probleem IMG',
        subtitle: 'Geef aan naar welke status dit dossier moet:',
      };
    }

    if (this.report.isPlanningOnHold) {
      settings.title = 'Dossier uit de bak halen';
      settings.subtitle = 'Geef aan naar welke status dit dossier moet:';
      settings.visible = this.report.isPlanningOnHold;
      this.statusChangeDialogOptions = {
        statusses: getStatusObjects([ReportStatus.CREATED, ReportStatus.PLANNING, ReportStatus.WERKVOORBEREIDING_REJECTED]),
        title: 'Verplaatsen uit: Planning On-hold',
        subtitle: 'Geef aan naar welke status dit dossier moet:',
      };
    }

    return settings;
  }

  protected get isArchived(): boolean {
    return !! this.$store.state.Auth.hasRole(UserRole.EXPERT) && !! this.report.archive_info && this.report.archive_info.is_archived_for_expert;
  }

  protected get isImmaterial() {
    return this.report.type?.uuid === ReportTypes.IMMATERIELESCHADE;
  }

  protected get isVES() {
    return this.report.type?.uuid === ReportTypes.VES_NULMETING;
  }

  protected get pendingSbApprovalBackStatus(): ReportStatus {
    if (this.report.isBezwaarReport) {
      return ReportStatus.OBJECTION_IN_PROGRESS;
    }
    switch (this.report.workflow) {
      case WorkflowEnum.NADER_ADVIES:
        return ReportStatus.ZIENSWIJZE_IN_PROGRESS;

      case WorkflowEnum.AMBTELIJK_BEZWAAR:
      case WorkflowEnum.BEZWAAR:
        return ReportStatus.OBJECTION_IN_PROGRESS;

      case WorkflowEnum.SMR:
        return ReportStatus.SMR_IN_PROGRESS;

      case WorkflowEnum.VES:
        return ReportStatus.OPNAME_IN_PROGRESS;

      case WorkflowEnum.REGULIER:
      default:
        return ReportStatus.IN_PROGRESS;
    }
  }

  protected get rejectVesZienswijzeDialogOptions(): Options {
    return {
      title: 'VES interesse wijzigen',
      text: `Weet je zeker dat je de VES interesse definitief wilt aanpassen naar ${VesStatusLabels[VesStatusEnum.UNINTERESTED]}?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Bevestigen',
          action: async () => {
            this.isLoading = true;
            try {
              await Promise.all([
                this.updateVesStatus(VesStatusEnum.UNINTERESTED),
                this.updateReportToStatus(ReportStatus.ZIENSWIJZE_IN_PROGRESS),
              ]);
            } catch (error) {
              ErrorHandler.network(error);
            } finally {
              this.isLoading = false;
              this.isSendingToStatus = true;
            }
          },
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }

  protected get changeVesInterestDialogOptions(): Options {
    return {
      title: 'VES interesse wijzigen',
      text: `Weet je zeker dat je de VES interesse wilt aanpassen naar ${VesStatusLabels[VesStatusEnum.UNINTERESTED]}?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Bevestigen',
          action: async () => {
            this.isLoading = true;
            try {
              await Promise.all([
                await this.updateVesStatus(VesStatusEnum.UNINTERESTED),
                await this.updateReportStatusWithRpc(ReportStatus.PLANNING),
              ]);
              this.isLoading = false;
              this.reloadReport();
            } catch (error) {
              ErrorHandler.network(error);
            } finally {
              this.isLoading = false;
            }
          },
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }

  protected get confirmHeropnameDialogOptions(): Options {
    return {
      title: 'Heropname aanmaken',
      text: 'Weet je zeker dat je een heropname wilt aanmaken voor dit dossier?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, aanmaken',
          action: () => {
            this.createHeropname();
          },
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }
}

export interface SendStatusDialog {
  title?: string;
  status?: string;
  description?: string;
  confirmAction?: Function | void;
  cancelAction?: Function | void;
  confirm?: string;
  cancel?: string;
}
