import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { cloneDeep } from 'lodash';
import { LateReason } from '@/models/LateReason';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { DateFormatter } from '@/support/DateFormatter';
import { userRoles } from '@/models/User';

@Component<ReportDeadline>({})
export default class ReportDeadline extends Vue {
  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  @Prop()
  protected report!: Report;

  protected reportRequestDeadline: Report | null = null;

  protected reasons: any[] = [];

  protected selectedReason = '';

  protected requested_deadline_reason = '';

  protected explainedReason = '';

  protected userRoles = userRoles;

  protected date = new DateFormatter();

  protected databaseDate = '0000-00-00 00:00:00';

  protected deadlineAt = new DateFormatter();

  protected expectedDeliveryAt = new DateFormatter();

  protected deadlineField: any = {
    deadlineNumberClass: 'positiveNumber',
    deadlineNumberType: '+',
    deadlineNumber: 0,
  };

  protected get allowEdit() {
    if (this.report.isClosed) {
      return false;
    }

    if (this.$store.state.isServiceOrganization) {
      return false;
    }

    if (this.$store.state.Auth.hasRole(this.userRoles.PreControllerRoles)) {
      return true;
    }

    if (this.report.hasStatus([
      'opname_in_progress',
      'in_progress',
      'rejected',
      'zienswijze_in_progress',
      'zienswijze_rejected',
      'objection_in_progress',
      'objection_rejected',
      'smr_in_progress',
      'smr_rejected',
    ])) {
      return true;
    }

    return false;
  }

  protected async mounted() {
    this.fetchLateReasons();
    this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    this.cloneReport();

    if (this.report.deadline_at && this.report.deadline_at !== this.databaseDate) {
      this.deadlineAt.selectedDate = DateTime.fromSQL(this.report.deadline_at).toFormat('yyyy-LL-dd');
    }

    if (this.report.expected_delivery_at && this.report.expected_delivery_at !== this.databaseDate) {
      this.expectedDeliveryAt.selectedDate = DateTime.fromSQL(this.report.expected_delivery_at).toFormat('yyyy-LL-dd');
    }

    this.parseDeadline();
    this.isLoading = false;
  }

  protected cloneReport() {
    this.reportRequestDeadline = cloneDeep(this.report);
  }

  protected async fetchLateReasons() {
    this.reasons = await new LateReason()
      .filter({ type: 'damage' })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected cancelReportDeadline() {
    this.isEditing = false;
    this.initialize();
  }

  protected parseDeadline() {
    if (this.report.deadline_at === '0000-00-00 00:00:00') {
      return;
    }

    const deadlineDate = DateTime.fromFormat(this.report.deadline_at as string, 'yyyy-LL-dd HH:mm:ss');
    const currentDate = DateTime.local();
    const difference = deadlineDate.diff(currentDate, 'days').toObject().days;
    const deadlineNumber = Math.floor((difference as any));

    this.deadlineField.deadlineNumber = deadlineNumber;

    if (deadlineNumber < 0) {
      this.deadlineField.deadlineNumberClass = 'negativeNumber';
      this.deadlineField.deadlineNumberType = '';
    } else if (deadlineNumber >= 0 && deadlineNumber < 5) {
      this.deadlineField.deadlineNumberClass = 'warningNumber';
      this.deadlineField.deadlineNumberType = '+';
    } else {
      this.deadlineField.deadlineNumberClass = 'positiveNumber';
      this.deadlineField.deadlineNumberType = '+';
    }
  }

  protected updateReport() {
    if (! this.report) {
      return;
    }

    this.isUpdating = true;

    const deadlineAt = this.deadlineAt.selectedDate ? DateTime.fromSQL(`${this.deadlineAt.selectedDate} 00:00:00`).toFormat('yyyy-LL-dd HH:mm:ss') : null;
    const expectedDeliveryAt = this.expectedDeliveryAt.selectedDate ? DateTime.fromSQL(`${this.expectedDeliveryAt.selectedDate} 00:00:00`).toFormat('yyyy-LL-dd HH:mm:ss') : null;

    const payload: {[key: string]: string} = {};

    if (deadlineAt) {
      payload.deadline_at = deadlineAt;
    }
    if (expectedDeliveryAt && expectedDeliveryAt !== this.report.expected_delivery_at) {
      payload.expected_delivery_at = expectedDeliveryAt;
      payload.late_reason = this.selectedReason;
    }

    this.report
      .update(payload)
      .then(() => {
        this.isUpdating = false;
        this.isEditing = false;
        this.$emit('reloadReport');
      })
      .catch((error: AxiosError) => {
        this.isUpdating = false;
        ErrorHandler.network(error);
      });
  }

  // Getters
  protected get hasDeadline(): boolean {
    return this.report ? this.report.deadline_at !== this.databaseDate : false;
  }
}
