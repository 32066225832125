var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "elementPanel elementPanel--additional profile" },
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              { staticClass: "profileContainer", attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", [
                  _c("span", { staticClass: "profile__userName" }, [
                    _vm._v("Risico profiel")
                  ])
                ])
              ],
              1
            ),
            [
              _c(
                "v-layout",
                { staticClass: "profileContainer" },
                [
                  _c(
                    "v-flex",
                    { attrs: { sm12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "", lg12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Gasveld")
                            ]),
                            _c(
                              "div",
                              { staticClass: "value" },
                              [
                                _vm.report.risk_profile.gasveld &&
                                _vm.report.risk_profile.gasveld.length > 1
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.report.risk_profile.gasveld
                                          ) +
                                          " "
                                      )
                                    ]
                                  : _vm.report.risk_profile.gasveld_6km &&
                                    _vm.report.risk_profile.gasveld_6km.length >
                                      1
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.report.risk_profile.gasveld_6km
                                          ) +
                                          " "
                                      )
                                    ]
                                  : [_vm._v("Overig effectgebied")]
                              ],
                              2
                            )
                          ]),
                          _c("v-flex", { attrs: { xs12: "", lg12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("# aardbevingen met een PGV >2.0")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.report.risk_profile
                                    .earthquakes_accountable
                                )
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Zwaarste Aardbeving")
                            ]),
                            _vm.report.risk_profile &&
                            _vm.report.risk_profile.heaviest
                              ? _c("div", { staticClass: "value" }, [
                                  _vm._v(
                                    " M" +
                                      _vm._s(
                                        _vm.report.risk_profile.heaviest
                                          .magnitude
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm.report.risk_profile.heaviest.city
                                      ) +
                                      " op " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          _vm.report.risk_profile.heaviest
                                            .occurred_at
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("v-flex", { attrs: { xs12: "", lg6: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Max. PGV 25%")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _c(
                                "span",
                                {
                                  class:
                                    "statusPill statusBackgroundColor--secondary"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.report.risk_profile.pgv_25pct
                                      ) +
                                      " mm/s "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "", lg6: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Max. PGV 1%")
                            ]),
                            _c("div", { staticClass: "value" }, [
                              _c(
                                "span",
                                {
                                  class:
                                    "statusPill statusBackgroundColor--primary"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.report.risk_profile.pgv_1pct) +
                                      " mm/s "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("v-flex", { attrs: { xs12: "", lg12: "" } }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Overige gebieden")
                            ]),
                            _c(
                              "div",
                              { staticClass: "value" },
                              [
                                _vm.report.risk_profile.map_areas
                                  ? [
                                      _vm.report.risk_profile.map_areas.zoutveld
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "statusPill statusBackgroundColor--zoutveld mr-2"
                                            },
                                            [_vm._v(" Zoutveld ")]
                                          )
                                        : _vm._e(),
                                      _vm.report.risk_profile.map_areas
                                        .peilgebied
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "statusPill statusBackgroundColor--peilgebied mr-2"
                                            },
                                            [_vm._v(" Peilvak ")]
                                          )
                                        : _vm._e(),
                                      _vm.report.risk_profile.map_areas
                                        .boezem200m
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "statusPill statusBackgroundColor--boezem200m mr-2"
                                            },
                                            [_vm._v(" Boezemvaart ")]
                                          )
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]),
                          _vm.report.risk_profile.project_media &&
                          _vm.report.risk_profile.project_media.length > 0
                            ? [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c("div", { staticClass: "label" }, [
                                    _vm._v("Instructies")
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "value" },
                                    _vm._l(
                                      _vm.report.risk_profile.project_media,
                                      function(media) {
                                        return _c(
                                          "p",
                                          {
                                            key:
                                              "project-media-" +
                                              media.uuid +
                                              "-cell"
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: media.original,
                                                  target: "_blank"
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("picture_as_pdf")
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(media.description) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm.report.risk_profile &&
                          _vm.report.risk_profile.heaviest
                            ? [
                                _vm.report.bouwjaar >=
                                _vm.report.risk_profile.heaviest.occurred_at.substring(
                                  0,
                                  4
                                )
                                  ? [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("div", { staticClass: "label" }, [
                                          _vm._v("Waarschuwing")
                                        ]),
                                        _c("div", { staticClass: "value" }, [
                                          _c(
                                            "p",
                                            [
                                              _c("v-icon", [_vm._v("warning")]),
                                              _vm._v(
                                                " Bouwjaar " +
                                                  _vm._s(_vm.report.bouwjaar) +
                                                  " later dan zwaarste beving (" +
                                                  _vm._s(
                                                    _vm.report.risk_profile.heaviest.occurred_at.substring(
                                                      0,
                                                      4
                                                    )
                                                  ) +
                                                  ") "
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ])
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }