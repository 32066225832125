var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "elementPanel elementPanel--additional" },
      [
        _vm.isLoading
          ? _c("LoaderCard", {
              attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
            })
          : _vm._e(),
        !_vm.isLoading
          ? [
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "profileContainer",
                    class: { editing: _vm.isEditing },
                    attrs: { "ma-0": "", row: "", wrap: "" }
                  },
                  [
                    !_vm.isEditing &&
                    _vm.$store.state.Auth.hasRole(["admin", "manager"]) &&
                    _vm.allowEdit &&
                    !_vm.syncing &&
                    !_vm.report.is_offline
                      ? _c("div", { staticClass: "iconContainer" }, [
                          _c(
                            "span",
                            { staticClass: "iconContainer__edit" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "edit-icon",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.isEditing = true
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [_vm._v("edit")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1471541473
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Deadline & Verwachte Opleverdatum aanpassen"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm.isEditing
                      ? _c(
                          "div",
                          { staticClass: "iconContainer" },
                          [
                            !_vm.isUpdating
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "cancel-icon",
                                    on: {
                                      click: function($event) {
                                        _vm.isEditing = false
                                        _vm.cancelReportDeadline()
                                      }
                                    }
                                  },
                                  [_vm._v(" cancel ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c("span", { staticClass: "profile__userName" }, [
                        _vm._v("Deadline & Verwachte Opleverdatum")
                      ])
                    ])
                  ],
                  1
                )
              ],
              !_vm.isEditing
                ? [
                    _c(
                      "v-layout",
                      {
                        staticClass: "profileContainer",
                        attrs: {
                          "ma-0": "",
                          row: "",
                          wrap: "",
                          "align-center": ""
                        }
                      },
                      [
                        _c("v-flex", { attrs: { sm12: "" } }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Huidige deadline")
                          ]),
                          _vm.reportRequestDeadline.deadline_at
                            ? _c("div", { staticClass: "value disabled" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.reportRequestDeadline.deadline_at
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "deadlineNumber",
                                    class: _vm.deadlineField.deadlineNumberClass
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.deadlineField.deadlineNumberType
                                        ) +
                                        _vm._s(
                                          _vm.reportRequestDeadline
                                            .stats_deadline_in_days
                                        ) +
                                        " dagen "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("v-flex", { attrs: { sm12: "" } }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Verwachte Opleverdatum")
                          ]),
                          _vm.reportRequestDeadline.expected_delivery_at
                            ? _c("div", { staticClass: "value disabled" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        _vm.reportRequestDeadline
                                          .expected_delivery_at
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("v-flex", { attrs: { sm12: "" } }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("Reden van vertraging")
                          ]),
                          _vm.reportRequestDeadline.late_reason
                            ? _c("div", { staticClass: "value disabled" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reportRequestDeadline.late_reason
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm.isEditing
                ? [
                    !_vm.isUpdating
                      ? _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { sm7: "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "dialog",
                                    attrs: {
                                      "close-on-content-click": false,
                                      lazy: "",
                                      "nudge-right": "100",
                                      "full-width": "",
                                      "max-width": "290px",
                                      "min-width": "290px"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-date",
                                      attrs: {
                                        slot: "activator",
                                        placeholder: "DD-MM-YYYY",
                                        label: "Huidige deadline",
                                        "error-messages":
                                          _vm.deadlineAt.errorMessage
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.deadlineAt.clear()
                                        }
                                      },
                                      slot: "activator",
                                      model: {
                                        value: _vm.deadlineAt.formattedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.deadlineAt,
                                            "formattedDate",
                                            $$v
                                          )
                                        },
                                        expression: "deadlineAt.formattedDate"
                                      }
                                    }),
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        "first-day-of-week": "1",
                                        locale: "nl-nl",
                                        min: "1910-01-01",
                                        color: "#837f16"
                                      },
                                      model: {
                                        value: _vm.deadlineAt.selectedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.deadlineAt,
                                            "selectedDate",
                                            $$v
                                          )
                                        },
                                        expression: "deadlineAt.selectedDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { sm7: "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "dialog",
                                    attrs: {
                                      "close-on-content-click": false,
                                      lazy: "",
                                      "nudge-right": "100",
                                      "full-width": "",
                                      "max-width": "290px",
                                      "min-width": "290px"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-date",
                                      attrs: {
                                        slot: "activator",
                                        placeholder: "DD-MM-YYYY",
                                        label: "Verwachte Opleverdatum",
                                        "error-messages":
                                          _vm.expectedDeliveryAt.errorMessage
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.expectedDeliveryAt.clear()
                                        }
                                      },
                                      slot: "activator",
                                      model: {
                                        value:
                                          _vm.expectedDeliveryAt.formattedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.expectedDeliveryAt,
                                            "formattedDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "expectedDeliveryAt.formattedDate"
                                      }
                                    }),
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        "first-day-of-week": "1",
                                        locale: "nl-nl",
                                        min: "1910-01-01",
                                        color: "#837f16"
                                      },
                                      model: {
                                        value:
                                          _vm.expectedDeliveryAt.selectedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.expectedDeliveryAt,
                                            "selectedDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "expectedDeliveryAt.selectedDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { sm12: "" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.reasons,
                                    "item-text": "name",
                                    "item-value": "id",
                                    "hide-details": "",
                                    label: "Reden van vertraging"
                                  },
                                  model: {
                                    value: _vm.selectedReason,
                                    callback: function($$v) {
                                      _vm.selectedReason = $$v
                                    },
                                    expression: "selectedReason"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-layout",
                      {
                        staticClass: "profileContainer",
                        attrs: { row: "", wrap: "", "align-center": "" }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.isUpdating
                              ? _c("LoaderCard", {
                                  attrs: {
                                    flat: "",
                                    type: "spinner--center",
                                    minHeight: "50px"
                                  }
                                })
                              : _vm._e(),
                            !_vm.isUpdating
                              ? _c(
                                  "MiButton",
                                  {
                                    attrs: {
                                      color: "primary",
                                      small: "true",
                                      outline: "true"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.updateReport($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Opslaan ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }