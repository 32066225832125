import { Vue, Component, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { cloneDeep, debounce } from 'lodash';
import { User, userRoles } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<ReportRoles>({})
export default class ReportRoles extends Vue {
  // #region @Props

  @Prop({ default: false })
  protected syncing!: boolean;

  @Prop()
  protected report!: Report;

  // #endregion

  // #region Props

  protected isLoading = true;

  protected isEditing = false;

  protected isUpdating = false;

  protected reportRoles: Report | null = null;

  protected experts: User[] = [];

  protected secondExperts: User[] = [];

  protected backOfficers: User[] = [];

  protected mediators: User[] = [];

  protected JZUsers: User[] = [];

  protected preControllers: User[] = [];

  protected technischCoordinators: User[] = [];

  protected selectedJZUser = '';

  protected selectedExpert = '';

  protected selectedSecondExpert = '';

  protected selectedBackOfficer = '';

  protected selectedMediator = '';

  protected selectedPreController = '';

  protected selectedTechnischCoordinator = '';

  protected userRoles = userRoles;

  // Debounce Search
  protected debouncedSearchJZUsers: Function = debounce(this.fetchJZUser, 300);

  protected debouncedSearch: Function = debounce(this.fetchExperts, 300);

  protected debouncedBackOfficerSearch: Function = debounce(this.fetchBackOfficers, 300);

  protected debouncedMediatorSearch: Function = debounce(this.fetchMediators, 300);

  protected debouncedSecondExpertSearch: Function = debounce(this.fetchSecondExperts, 300);

  protected debouncedPreControllerSearch: Function = debounce(this.fetchPreControllers, 300);

  protected debouncedTechnischCoordinatorSearch: Function = debounce(this.fetchTechnischCoordinators, 300);

  protected JZUserSearch = '';

  protected expertSearch = '';

  protected secondExpertSearch = '';

  protected backOfficerSearch = '';

  protected mediatorSearch = '';

  protected preControllerSearch = '';

  protected technischCoordinatorSearch = '';

  // #endregion

  // #region Lifecycle hooks / Init

  protected async mounted() {
    await this.initialize();
  }

  protected async initialize() {
    this.isLoading = true;
    this.reportRoles = cloneDeep(this.report);

    if (this.reportRoles.expert) {
      this.selectedExpert = this.reportRoles.expert && this.reportRoles.expert.uuid ? this.reportRoles.expert.uuid : '';
      this.experts.push(this.reportRoles.expert as User);
    } else {
      await this.fetchExperts();
    }

    if (this.reportRoles.second_expert) {
      this.selectedSecondExpert = this.reportRoles.second_expert && this.reportRoles.second_expert.uuid ? this.reportRoles.second_expert.uuid : '';
      this.secondExperts.push(this.reportRoles.second_expert as User);
    } else {
      await this.fetchSecondExperts();
    }

    if (this.reportRoles.report_handler) {
      this.selectedBackOfficer = this.reportRoles.report_handler && this.reportRoles.report_handler.uuid ? this.reportRoles.report_handler.uuid : '';
      this.backOfficers.push(this.reportRoles.report_handler as User);
    } else {
      await this.fetchBackOfficers();
    }

    if (this.reportRoles.mediator) {
      this.selectedMediator = this.reportRoles.mediator && this.reportRoles.mediator.uuid ? this.reportRoles.mediator.uuid : '';
      this.mediators.push(this.reportRoles.mediator as User);
    } else {
      await this.fetchMediators();
    }

    if (this.reportRoles.jz_user) {
      this.selectedJZUser = this.reportRoles.jz_user && this.reportRoles.jz_user.uuid ? this.reportRoles.jz_user.uuid : '';
      this.JZUsers.push(this.reportRoles.jz_user as User);
    } else {
      await this.fetchJZUser();
    }

    if (this.reportRoles.pre_controller) {
      this.selectedPreController = this.reportRoles.pre_controller && this.reportRoles.pre_controller.uuid ? this.reportRoles.pre_controller.uuid : '';
      this.preControllers.push(this.reportRoles.pre_controller as User);
    } else {
      await this.fetchPreControllers();
    }

    if (this.reportRoles.technisch_coordinator) {
      this.selectedTechnischCoordinator = this.reportRoles.technisch_coordinator && typeof this.reportRoles.technisch_coordinator !== 'string' && this.reportRoles.technisch_coordinator.uuid ? this.reportRoles.technisch_coordinator.uuid : '';
      this.technischCoordinators.push(this.reportRoles.technisch_coordinator as User);
    } else {
      await this.fetchTechnischCoordinators();
    }

    this.isLoading = false;
  }

  // #endregion

  // #region Handlers

  protected cancelEditReportRoles() {
    this.isEditing = false;
    this.reportRoles = cloneDeep(this.report);
    this.selectedExpert = this.reportRoles.expert && this.reportRoles.expert.uuid ? this.reportRoles.expert.uuid : '';
    this.selectedSecondExpert = this.reportRoles.second_expert && this.reportRoles.second_expert.uuid ? this.reportRoles.second_expert.uuid : '';
    this.selectedBackOfficer = this.reportRoles.report_handler && this.reportRoles.report_handler.uuid ? this.reportRoles.report_handler.uuid : '';
    this.selectedMediator = this.reportRoles.mediator && this.reportRoles.mediator.uuid ? this.reportRoles.mediator.uuid : '';
    this.selectedJZUser = this.reportRoles.jz_user && this.reportRoles.jz_user.uuid ? this.reportRoles.jz_user.uuid : '';
    this.selectedPreController = this.reportRoles.pre_controller && this.reportRoles.pre_controller.uuid ? this.reportRoles.pre_controller.uuid : '';
    this.selectedTechnischCoordinator = this.reportRoles.technisch_coordinator && typeof this.reportRoles.technisch_coordinator !== 'string' && this.reportRoles.technisch_coordinator.uuid ? this.reportRoles.technisch_coordinator.uuid : '';
  }

  // #endregion

  // #region API Requests

  protected async fetchExperts() {
    this.experts = await new User()
      .filter('search', this.expertSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchSecondExperts() {
    this.secondExperts = await new User()
      .filter('search', this.secondExpertSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchPreControllers() {
    this.preControllers = await new User()
      .filter('search', this.preControllerSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchTechnischCoordinators() {
    this.technischCoordinators = await new User()
      .filter('search', this.technischCoordinatorSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchBackOfficers() {
    this.backOfficers = await new User()
      .sort('name', 'ASC')
      .filter('search', this.backOfficerSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async fetchMediators() {
    this.mediators = await new User()
      .sort('name', 'ASC')
      .filter({ type: 'case_mediator' })
      .filter('search', this.mediatorSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async fetchJZUser() {
    this.JZUsers = await new User()
      .sort('name', 'ASC')
      .filter('search', this.JZUserSearch)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected updateReport() {
    this.isUpdating = true;

    if (! this.reportRoles) {
      return;
    }

    const payload: UpdateRolesPayload = {};

    if (! this.$store.state.isServiceOrganization) {
      payload.expert = this.selectedExpert ? this.selectedExpert : '';
      payload.second_expert = this.selectedSecondExpert ? this.selectedSecondExpert : '';
      payload.pre_controller = this.selectedPreController ? this.selectedPreController : '';
      payload.technical_coordinator = this.selectedTechnischCoordinator ? this.selectedTechnischCoordinator : '';
    }

    if (this.$store.state.isServiceOrganization) {
      payload.report_handler = this.selectedBackOfficer ? this.selectedBackOfficer : '';
      payload.mediator = this.selectedMediator ? this.selectedMediator : '';
      payload.jz_user = this.selectedJZUser ? this.selectedJZUser : '';
    }

    this.report.update(payload)
      .then(() => {
        this.$emit('reloadReport');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isUpdating = false;
        this.isEditing = false;
      });
  }

  // #endregion

  // #region Getters & Setters

  protected get allowEdit() {
    if (! this.reportRoles) return false;

    if (this.report.isClosed) {
      return false;
    }

    // TCMG
    if (this.$store.state.isServiceOrganization) {
      return ! this.$store.state.Auth.hasRole('serviceloket');
    }

    if (this.reportRoles.isWoningCooperatie2Report
    || this.reportRoles.isWoningCooperatie2ReportNew
    || this.reportRoles.isWoningCooperatie1Report
    || this.reportRoles.isWoningCooperatie1ReportNew
    ) {
      return true;
    }

    if (this.reportRoles.isAannemersReport && this.report.hasStatus('planning_organization')) {
      return this.$store.state.Auth.hasRole(this.userRoles.DeskundigeRoles);
    }

    return this.$store.state.Auth.hasRole(this.userRoles.PreControllerRoles);
  }

  protected get canEditExpert(): boolean {
    if (this.report.isWoningCooperatie2Report
    || this.report.isWoningCooperatie2ReportNew
    || this.report.isWoningCooperatie1Report
    || this.report.isWoningCooperatie1ReportNew
    || this.report.isAannemersReport
    ) {
      return true;
    }

    return ! this.$store.state.Auth.hasRole(['expert']);
  }

  // #endregion
}

// #region Enums, Types & Interfaces

interface UpdateRolesPayload {
  expert?: string;
  second_expert?: string;
  mediator?: string;
  report_handler?: string;
  jz_user?: string;
  pre_controller?: string;
  technical_coordinator?: string;
}

// #endregion
