import { Component, Vue, Prop } from 'vue-property-decorator';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Report } from '@/models/Report';

@Component<ZienswijzeDialog>({})
export default class ZienswijzeDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected isLoading = false;

  protected is_prio = false;

  protected close() {
    this.$emit('input', false);
  }

  protected create() {
    if (! this.report) {
      return;
    }

    this.isLoading = true;

    const payload: {[key: string]: any} = {
      signature: 'zienswijze:create',
      body: {
        report: this.report.case_number,
        is_prio: this.is_prio,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.isLoading = false;
        this.$store.dispatch('openDialog', this.dialogOptionsReportConfirm);
        this.close();
      });
  }

  private get dialogOptionsReportConfirm(): Options {
    return {
      title: 'Gelukt!',
      text: `Er wordt nu een nieuwe dossier klaar gezet in de bak ${this.report.isBezwaarReport ? '"Bezwaar Lopend"' : '"Herzien rapport Lopend"'}.`,
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
        },
      },
    };
  }
}
